import { useState } from "react";
import { useSelector } from "react-redux";

import { Form, Input, Drawer, Button } from "antd";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";

import REQUESTS from "../../../api/requests";

import ErrorMessage from "../../../components/ErrorMessage";

import styles from "../style/addActivationDrawer.module.scss";
import { useEffect } from "react";

export default function AddActivationDrawer({
  visible,
  setAddActivationDrawer,
  getResellers,
  selectedItem,
  setSelectedItem,
}) {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [form] = Form.useForm();

  const translation = useSelector(selectTranslation);
  const onClose = () => {
    setAddActivationDrawer(false);
    form.resetFields();
  };
  function onFinish(values) {
    setLoading(false);

    function callback(data) {
      getResellers();
      onClose();
      setSelectedItem(null);
    }

    function errorCallback(err) {
      setMessage({ type: false, text: err });
    }
    REQUESTS.RESELLER_ACTIVATIONS.POST(
      {
        reseller_id: selectedItem?.id,
        count: +values.count,
      },
      callback,
      errorCallback
    );
  }

  function onFinishFailed() {
    setLoading(false);
  }

  const onSubmit = () => {
    setLoading(true);
    form.submit();
  };

  return (
    <Drawer
      title={translation["Add activations"] || TEXT["Add activations"]}
      placement="right"
      onClose={onClose}
      open={visible}
      width={560}
    >
      <Form
        form={form}
        name="Add activations"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        initialValues={{
          password: "",
        }}
      >
        <div className={styles["add-activations-form-content"]}>
          <Form.Item
            label={translation["Count"] || TEXT["Count"]}
            name="count"
            rules={[
              {
                required: true,
                message: "Please input count",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <ErrorMessage message={message} />
          <div className={styles["add-activations-form-content__btn"]}>
            <Form.Item>
              <Button
                // className={classes["save_button"]}
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{
                  width: 200,
                }}
                onClick={onSubmit}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    </Drawer>
  );
}
