import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Button, Form, Input, notification } from "antd";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import { selectwidgets } from "../../../features/widgets/selector";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";


export default function XtreamUI() {
  const [loading, setLoading] = useState();

  const translation = useSelector(selectTranslation);
  const widgets = useSelector(selectwidgets);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    const body = {
      access_code: values.access_code,
      api_host: values.api_host,
      api_key: values.api_key,
    };

    if (typeof values.free_trial_packages === "object") {
      body["free_trial_packages"] = values.free_trial_packages;
    } else if (typeof values.free_trial_packages === "string") {
      body["free_trial_packages"] = values.free_trial_packages
        ?.split(",")
        .map(Number);
    }

    if (typeof values.subscription_packages === "object") {
      body["subscription_packages"] = values.subscription_packages;
    } else if (typeof values.subscription_packages === "string") {
      body["subscription_packages"] = values.subscription_packages
        ?.split(",")
        .map(Number);
    }

    REQUESTS.XTREAM_UI.EDIT(
      body,
      (data) => {
        setLoading(false);
        getXtreamUiConfig();
        notification.open({
          message: "Xtream UI Settings",
          description: data.message,
          duration: 5,
        });
      },
      (err) => {

        notification.error({
          message: "Xtream UI Settings",
          description: err,
          duration: 5,
        });
        setLoading(false);
      }
    );
  };

  const onSubmit = () => {
    setLoading(true);
    form.submit();
  };

  const getXtreamUiConfig = () => {
    REQUESTS.XTREAM_UI.GET(
      (data) => {
        form.setFields([
          {
            name: "access_code",
            value: data.access_code,
          },
          {
            name: "api_key",
            value: data.api_key,
          },
          {
            name: "api_host",
            value: data.api_host,
          },
          {
            name: "free_trial_packages",
            value: JSON.parse(data.free_trial_packages),
          },
          {
            name: "subscription_packages",
            value: JSON.parse(data.subscription_packages),
          },
        ]);
      },
      (err) => { }
    );
  };

  useEffect(() => {
    getXtreamUiConfig();
  }, []);

  return (
    <div>
      {widgets &&
        widgets.xuione &&
        widgets.xuione.enabled &&
        widgets.xuione.enabled === "false" && (
          <>
            <div className="pro-widget-warning-text">
              To use this feature you need to upgrade to Pro Version
              <br />
              Please contact us for more details.
            </div>
            <div className="pro-widget-tab-layer"></div>
          </>
        )}
      <div>
        <h3 className="xtream-ui-title">Xtream UI</h3>
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
        >
          <div style={{ width: 250 }}>
            <Form.Item
              name="access_code"
              label={translation["Access code"] || TEXT["Access code"]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              name="api_key"
              label={translation["API Key"] || TEXT["API Key"]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="api_host"
              label={translation["HOST"] || TEXT["HOST"]}
            >
              <Input />
            </Form.Item>
            <p className="xtream-ui-ids-title">
              {translation["Put IDs with commas"] ||
                TEXT["Put IDs with commas"]}
            </p>
            <Form.Item
              name="free_trial_packages"
              label={
                translation["Free Trial Packages"] ||
                TEXT["Free Trial Packages"]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="subscription_packages"
              label={
                translation["Subscription packages"] ||
                TEXT["Subscription packages"]
              }
            >
              <Input />
            </Form.Item>


            <div>
              <Form.Item>
                <Button
                  type="primary"
                  loading={loading}
                  style={{ width: 250 }}
                  onClick={onSubmit}
                >
                  {translation["Save"] || TEXT["Save"]}
                </Button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
