import { useState, useEffect, useRef } from "react";

import { PlusOutlined } from "@ant-design/icons";

import ImgCrop from "antd-img-crop";

import { EditorState, ContentState, convertFromHTML } from 'draft-js';

import { stateToHTML } from "draft-js-export-html";

import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'draft-js/dist/Draft.css';

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import ErrorMessage from "../../../components/ErrorMessage";

import classes from "../style/newsTableDrawer.module.scss";

import { Button, Form, Input, Drawer, Upload } from "antd";

const { TextArea } = Input;

const NewsTableDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  followAddElement,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const useScrollRef = useRef();
  const useParentRef = useRef();
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const [newsImg, setNesImg] = useState([]);
  const [urlNewsImg, setUrlNesImg] = useState("");
  const [fileList, setFileList] = useState([
    {
      uid: "-1",
      name: "image.png",
      status: "done",
      url: "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png",
    },
  ]);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  function createFileList(newsImg, urlNewsImg) {
    setFileList([
      {
        uid: newsImg[0]?.uid,
        name: newsImg[0]?.name,
        status: "done",
        url: newsImg ? urlNewsImg : "def",
      },
    ]);
  }

  function deleteFile(newsImg) {
    if (newsImg.length < 1) {
      setFileList([]);
    }
  }

  const propsNewsImg = {
    onRemove: (file) => {
      const index = newsImg.indexOf(file);
      const newFileList = newsImg.slice();
      newFileList.splice(index, 1);
      setNesImg(newFileList);
    },
    beforeUpload: (file) => {
      setNesImg([file]);
      //   setNesImg([file]);
      const myurl = window.URL
        ? window.URL.createObjectURL(file)
        : window.webkitURL.createObjectURL(file);
      setUrlNesImg(myurl);

      return false;
    },
    newsImg,
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  function scrollIntoView() {
    setTimeout(() => {
      if(useScrollRef.current){
        useScrollRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
      }
    }, 200);
  }

  const onFinish = (values) => {

    setLoading(true);

    setMessage({
      type: false,
      text: "",
    });

    const formData = new FormData();

    formData.append("title", values.title);
    formData.append("description", stateToHTML(editorState.getCurrentContent()));

    if (newsImg) {
      newsImg.forEach((file) => {
        formData.append("image", file);
      });
    }

    function callback() {
      if (!current) {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
        followAddElement.setDate("");
      }

      // if (newsImg.length == 0) {
      //   setMessage({ type: false, text: "Please select image" });
      //   setLoading(false);
      // }

      setLoading(false);
      onClose();
      getData();
    }

    function errorCallback(err) {
      setLoading(false);

      if (newsImg.length == 0) {
        setMessage({ type: false, text: "Please select image" });
        setLoading(false);
      } else {
        setMessage({ type: false, text: err });
      }
    }

    if (current && current.id) {
      formData.append("id", current.id);

      REQUESTS.NEWS.EDIT(formData, callback, errorCallback);
    } else {
      REQUESTS.NEWS.ADD(formData, callback, errorCallback);
    }
  };

  useEffect(() => {
    if (current) {
      const blocksFromHTML = convertFromHTML(current.description);

      const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

      setEditorState(EditorState.createWithContent(contentState));

      form.setFields([
        { name: "title", value: current.title },
        { name: "description", value: current.description },
      ]);

      setFileList([
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: current?.image,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [current]);

  useEffect(() => {
    createFileList(newsImg, urlNewsImg);
    deleteFile(newsImg);
  }, [newsImg]);

  useEffect(() => {
    if (visible) {
      scrollIntoView();
    } else {
      form.resetFields();
      setEditorState(EditorState.createEmpty())
      setLoading(false);
      setNesImg([]);
    }
  }, [visible]);

  // useEffect(() => {
  //   if (current) {
  //     form.setFields([
  //       {
  //         name: "title",
  //         value: current.title,
  //       },

  //       {
  //         name: "description",
  //         value: current.description,
  //       },
  //     ]);
  //   } else {
  //     form.resetFields();
  //   }
  // }, [current, visible]);

  return (
    <div ref={useParentRef}>
      <Drawer
        title={
          current
            ? `${translation["Edit"] || TEXT["Edit"]} ${translation["News"] || TEXT["News"]
            }`
            : `${translation["Add"] || TEXT["Add"]} ${translation["News"] || TEXT["News"]
            }`
        }
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <div ref={useScrollRef}></div>
        <Form
          form={form}
          name="profile"
          layout="vertical"
          onFinish={onFinish}
          onValuesChange={onValuesChange}
        >
          <Form.Item
            label={translation["Title"] || TEXT["Title"]}
            name="title"
            rules={[
              {
                required: true,
                message: "Please input name",
              },
            ]}
          >
            <Input />
          </Form.Item>

          {/* <Form.Item
            label={translation["Description"] || TEXT["Description"]}
            name="description"
            rules={[
              {
                required: true,
                message: "Please input description",
              },
            ]}
          >
            <TextArea rows={4} />
          </Form.Item> */}

          <Form.Item
            name="description"
            label={"Description"}
            rules={[
              {
                required: true,
                message: "Please input description",
              },
            ]}
          >
            <Editor
              editorState={editorState}
              onEditorStateChange={setEditorState}
              wrapperClassName={classes["wrapper-class"]}
              editorClassName={classes["editor-class"]}
              className={classes["DraftEditor-editor"]}
              toolbar={{
                options: ['inline', 'list', 'textAlign', 'link', 'history'],
                inline: { options: ['bold', 'italic', 'underline'] },
                textAlign: { inDropdown: true },
              }}
            />
          </Form.Item>

          <Form.Item>
            <div className={classes["app_uload_form"]}>
              <ImgCrop
                aspect={329 / 320}
                quality={0.4}
                minZoom={1}
                fillColor="transparent"
                grid={true}
                rotate
              >
                <Upload
                  {...propsNewsImg}
                  fileList={fileList}
                  listType="picture-card"
                // onChange={() => {
                //   setFileList([]);
                //   setNesImg([]);
                // }}
                >
                  {uploadButton}
                </Upload>
              </ImgCrop>
            </div>
          </Form.Item>
          <ErrorMessage message={message} />
          <div className={classes["save_button_div"]}>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className={classes["save_button"]}
                loading={loading}
              >
                {translation["Save"] || TEXT["Save"]}
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Drawer>
    </div>
  );
};

export default NewsTableDrawer;
