import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Menu, Button } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  MenuOutlined,
} from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import ProfileDropdown from "./ProfileDropdown";

import {
  selectUser,
  editUser,
} from "../../features/DataUserProfile/DataUserProfileSlise";

import { editAppInfo } from "../../features/appInfoSlice/AppInfoSlice";

import classes from "../style/header.module.scss";

function HeaderComponent({ setCollapsed, collapsed, windowSize, showDrawer }) {
  const dispatch = useDispatch();

  const userInfo = useSelector(selectUser);

  const getApplication = () => {
    const collback = (data) => {
      dispatch(editAppInfo(data));
    };

    REQUESTS.APP_INFO.GET(collback);
  };

  const getProfile = () => {
    function collback(data) {
      dispatch(editUser(data));
    }

    function errorCollback(data) {
      dispatch(editUser(data));
    }

    REQUESTS.PROFILE.GET(collback, errorCollback);
  };

  useEffect(() => {
    getApplication();
    getProfile();
  }, []);

  return (
    <Menu mode="horizontal" style={{ paddingRight: "50px" }}>
      <div className={classes["header"]}>
        {windowSize > 800 ? (
          <Button type="text" onClick={() => setCollapsed(!collapsed)} className='header-tigger-btn'>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
              }
            )}
          </Button>
        ) : (
          <MenuOutlined onClick={showDrawer}  style={{margin:"0 10px"}}/>
        )}
        <div className={classes["container"]}>
          {/* <LanguageBlock /> */}
          <ProfileDropdown userInfo={userInfo} />
        </div>
      </div>
    </Menu>
  );
}

export default HeaderComponent;
