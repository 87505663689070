import { useState, useEffect } from "react";
import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/packageList.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";

import { Button, Form, Input, Drawer } from "antd";

const PackageListDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  followAddElement,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  useEffect(() => {
    if (visible === false) {
      setLoading(false);
      onValuesChange();
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name,
      count: Number(values.count),
      price: Number(values.price),
    };

    function callback() {
      if (!current) {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
      }
      setLoading(false);
      onClose();
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    if (current) {
      body.id = current.id;
      REQUESTS.PACKEGES.EDIT(body, callback, errorCallback);
    } else {
      REQUESTS.PACKEGES.ADD(body, callback, errorCallback);
    }
  };

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "count",
          value: current.count,
        },

        {
          name: "price",
          value: current.price,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [current, visible]);

  return (
    <Drawer
      title={
        current
          ? `${translation["Edit"] || TEXT["Edit"]} 
            ${translation["Credit"] || TEXT["Credit"]}`
          : `${translation["Add"] || TEXT["Add"]}
             ${translation["Credit"] || TEXT["Credit"]}`
      }
      placement="right"
      onClose={onClose}
      open={visible}
    >
      <Form
        form={form}
        name="profile"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={{
          password: "",
        }}
      >
        <Form.Item
          label={translation["Name"] || TEXT["Name"]}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input  name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={translation["Price"] || TEXT["Price"]}
          name="price"
          rules={[
            {
              required: true,
              message: "Please input price",
            },
          ]}
        >
          <Input type={"number"} min={0} />
        </Form.Item>

        <Form.Item
          label={translation["Count"] || TEXT["Count"]}
          name="count"
          rules={[
            {
              required: true,
              message: "Please input count",
            },
          ]}
        >
          <Input type={"number"} min={0} />
        </Form.Item>

        <ErrorMessage message={message} />
        <div className={classes["save_button_div"]}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={classes["save_button"]}
              loading={loading}
            >
              {translation["Save"] || TEXT["Save"]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default PackageListDrawer;
