import { useState, useEffect } from "react";

import { useDispatch } from "react-redux";

import { Input } from "antd";

import { SearchOutlined } from "@ant-design/icons";

import { editChatList } from "../../../../features/chat/chatSlise";

import { get_chat_list } from "../../requests/realTimechatRequests";

import classes from "../../style/searchChat.module.scss";

function SearchChat() {
  const dispatch = useDispatch();

  const [seacrinputValue, setSeacrinputValue] = useState();

  const getList = (data) => {
    dispatch(editChatList(data));
  };

  const query = {
    search: JSON.stringify({ mac: seacrinputValue }),
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      if (seacrinputValue !== undefined) {
        get_chat_list(getList, query);
      }
    }, 500);

    return () => {
      clearTimeout(timout);
    };
  }, [seacrinputValue]);

  return (
      <Input
        placeholder="search"
        onChange={(e) => {
          setSeacrinputValue(e.target.value);
        }}
        className={classes["search_input"]}
        allowClear
        suffix={<SearchOutlined className={classes["search_input_icon"]} />}
      />
  );
}

export default SearchChat;
