import { useState, useEffect } from "react";

import { Button, Form, Input, Drawer } from "antd";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/blacklist.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";
import MacMaskComponent from "../../../components/MacMaskComponent";


const BlacklistDrawer = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  followAddElement,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const [macValue, setMacValue] = useState();

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  useEffect(() => {
    if (visible === false) {
      setLoading(false);
      onValuesChange();
    }
  }, [visible]);

  const onFinish = (values) => {
    setLoading(true);
    let body;
    if (values.ip || macValue || values.deviceId) {
      body = {
        ip: values.ip,
        mac: macValue,
        deviceId: values.deviceId,
      };

      function callback() {
        followAddElement.setCurrentPage(1);
        followAddElement.setSearch("");
        followAddElement.setSort(["id", "DESC"]);
        followAddElement.setCurrentPage(1);
        followAddElement.setLimit(10);
        followAddElement.setTotal();
        followAddElement.setDate("");
        setLoading(false);
        onClose();
        getData();
      }

      function errorCallback(err) {
        setLoading(false);
        setMessage({ type: false, text: err });
      }

      REQUESTS.BLACKLIST.ADD(body, callback, errorCallback);
    } else {
      setMessage({ type: false, text: "Please input Ip or Mac or Device Id" });
      setLoading(false);
    }
  };

  useEffect(() => {
    form.resetFields();
  }, [current, visible]);

  return (
    <Drawer
      title={translation[`Add Blacklist`] || TEXT["Add Blacklist"]}
      placement="right"
      onClose={onClose}
      open={visible}
    >
      <Form
        form={form}
        name="profile"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={{
          password: "",
        }}
      >
        <Form.Item label={translation["Ip"] || TEXT["Ip"]} name="ip">
          <Input />
        </Form.Item>

        <MacMaskComponent  value={macValue} setValue={setMacValue} />

        <Form.Item
          label={translation["Device id"] || TEXT["Device id"]}
          name="deviceId"
        >
          <Input />
        </Form.Item>

        <ErrorMessage message={message} />
        <div className={classes["save_button_div"]}>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className={classes["save_button"]}
              loading={loading}
            >
              {translation["Save"] || TEXT["Save"]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default BlacklistDrawer;
