import React, { useEffect, useState } from 'react';

import { EditorState, ContentState, convertFromHTML } from 'draft-js';

import { stateToHTML } from "draft-js-export-html";

import { Editor } from 'react-draft-wysiwyg';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import 'draft-js/dist/Draft.css';

import { Drawer, Input, Button, Form, } from 'antd';

import ErrorMessage from '../../../components/ErrorMessage';

import REQUESTS from '../../../api/requests';

import styles from "../index.module.scss"

export default function ApplicationDrawer({ current, onClose, visible, getData }) {
    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [windowSize, setWindowSize] = useState(window.innerWidth);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [file, setFile] = useState(null);

    const [progress, setProgress] = useState(0);

    const [message, setMessage] = useState({
        type: false,
        text: "",
    });

    const onFinish = (values) => {

        setLoading(true);

        setMessage({ type: false, text: "" });

        const formData = new FormData();

        if (file) {
            formData.append('file', file);
        }

        formData.append('title', values.title);

        formData.append('description', stateToHTML(editorState.getCurrentContent()));

        const onUploadProgress = (progressEvent) => {
            const percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
            setProgress(percentage);
        }

        if (current) {
            formData.append('id', current.id);

            REQUESTS.APPLICATIONS.PUT(formData, (data) => {
                setLoading(false);

                onClose();
                getData();
                setProgress(null)
            }, (err) => {
                setLoading(false)
                setMessage({ type: false, text: err });
            }, onUploadProgress)
        } else {

            REQUESTS.APPLICATIONS.POST(formData, (data) => {
                setLoading(false);
                getData();
                onClose();
                setProgress(null)
            }, (err) => {
                setLoading(false)
                setMessage({ type: false, text: err });
            }, onUploadProgress)
        }
    }

    useEffect(() => {
        if (current) {
            const blocksFromHTML = convertFromHTML(current.description);

            const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap);

            setEditorState(EditorState.createWithContent(contentState));

            form.setFields([
                { name: "title", value: current.title },
                { name: "description", value: current.description },
            ]);
        }
    }, [current]);

    useEffect(() => {
        if (!visible) {
            setMessage({ type: false, text: "" });

            setEditorState(EditorState.createEmpty());

            setLoading(false);

            form.resetFields();

            setFile(null);

            setProgress(null)
        }
    }, [visible]);

    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize(window.innerWidth);
        };

        window.addEventListener("resize", handleWindowResize);

        return () => {
            window.removeEventListener("resize", handleWindowResize);
        };
    }, []);

    return (
        <Drawer
            title={current ? "Edit" : "Add"}
            placement="right"
            onClose={onClose}
            open={visible}
            width={windowSize < 900 ? "90%" : "40%"}
        >
            <Form
                form={form}
                name="profile"
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    name="title"
                    label={"Title"}
                    rules={[
                        {
                            required: true,
                            message: "Please input title",
                        },
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label={"Description"}
                    rules={[
                        {
                            required: true,
                            message: "Please input description",
                        },
                    ]}
                >
                    <Editor
                        editorState={editorState}
                        onEditorStateChange={setEditorState}
                        wrapperClassName={styles["wrapper-class"]}
                        editorClassName={styles["editor-class"]}
                        className={styles["DraftEditor-editor"]}
                        toolbar={{
                            options: ['inline', 'list', 'textAlign', 'link', 'history'],
                            inline: { options: ['bold', 'italic', 'underline'] },
                            textAlign: { inDropdown: true },
                        }}
                    />
                </Form.Item>
                <Form.Item
                    name="file"
                    label={"File"}
                >
                    <Input
                        type="file"
                        accept={[".wgt", ".ipk", ".apk", ".zip"]}
                        className='aplication-file'
                        onChange={(e) => {
                            setFile(e.target.files[0]);
                        }}
                    />
                    {progress && <div>Upload : {progress}%</div>}
                </Form.Item>

                {
                    current  && <a href={current.file} download style={{color:"blue"}}>
                        {current.file && current.file.substring(current.file.lastIndexOf("/") + 1)}
                    </a>
                }
                <ErrorMessage message={message} />

                <Form.Item>
                    <div className={styles['save-btn']}>
                        <Button
                            type="primary"
                            loading={loading}
                            onClick={() => form.submit()}
                        >
                            Save
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
