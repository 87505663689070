import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import { Button, Input, notification } from "antd";

import Language from "../web-translation/Language";

import REQUESTS from "../../../../api/requests";

import styles from "./style.module.scss";

export default function ResellerTranslation() {
    const [languages, setLanguages] = useState([]);

    const [translations, setTranslations] = useState(null);

    const [selectedLanguage, setSelectedLanguage] = useState("en");

    const getTranslations = () => {
        function callback(data) {
            const resellerTranslation = JSON.parse(data.reseller_translations);
            let newTranslations = {};

            for (let i = 0; i < languages.length; i++) {
                const languageTranslations = [];

                const code = Object.keys(resellerTranslation).find(
                    (item) => item == languages[i].iso_code
                );

                for (let property in resellerTranslation["en"]) {
                    const value =
                        code == languages[i].iso_code ? resellerTranslation[code][property] : "";

                    languageTranslations.push({
                        key: property,
                        label: resellerTranslation["en"][property],
                        value,
                    });
                }
                newTranslations[languages[i].iso_code] = languageTranslations;
                setTranslations(newTranslations);
            }
        }

        REQUESTS.TRANSLATIONS.GET({}, callback);
    };

    const editTranslations = () => {
        const newObj = {};

        for (let property in translations) {
            const translation = {};

            for (let key in translations[property]) {
                translation[translations[property][key]["key"]] =
                    translations[property][key]["value"];
            }
            newObj[property] = translation;
        }

        const body = { reseller_translations: JSON.stringify(newObj) };

        function callback(data) {
            getTranslations();
            notification.success({
                message: "Success",
                description: "Translation saved successfully",
            });
        }

        REQUESTS.RESELLER_TRANSLATION_UPDATE(body, callback);
    };

    const handleChangeTranslation = (key, value) => {
        const changedTranslation = translations[selectedLanguage].map((translation) => {
            if (translation.key === key) {
                return {
                    ...translation,
                    value: value,
                };
            }
            return translation;
        });

        setTranslations({
            ...translations,
            [selectedLanguage]: changedTranslation,
        });
    };

    useEffect(() => {
        if (languages) {
            getTranslations();
        }
    }, [languages]);

    return (
        <div className={styles["reseller-traslaton-section"]}>
            <div className={styles["reseller-traslaton-section-title"]}>
                <p className={styles["title"]}> Reseller Translation</p>

                <Language setList={setLanguages} onChange={setSelectedLanguage} />
                <Button type="primary" style={{ width: 120 }} onClick={editTranslations}>
                    Save
                </Button>
            </div>

            <div>
                {(() => {
                    if (translations) {
                        const code = Object.keys(translations).find(
                            (item) => item === selectedLanguage
                        );
                        return translations[code].map((translation) => {
                            return (
                                <div
                                    key={translation.key}
                                    className={styles["tarnslation-container"]}
                                >
                                    <div className={styles["label"]}>{translation.label}</div>
                                    <Input.TextArea
                                        autoSize
                                        placeholder="Enter text"
                                        value={translation.value}
                                        onChange={(e) =>
                                            handleChangeTranslation(translation.key, e.target.value)
                                        }
                                    />
                                </div>
                            );
                        });
                    }
                })()}
            </div>
        </div>
    );
}
