import REQUESTS from "../../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Button, Input } from "antd";
import TableButtons from "../../../components/TableButtons";
import classes from "../style/packageList.module.scss";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import TEXT from "../../../config/text";
import {
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import showPropsConfirm from "../../../components/showPropsConfirm";
import PackageListDrawer from "./PackageListDrawer";
import InitialParams from "../../../components/InitialParams";
import { createSearchParams, useNavigate } from "react-router-dom";
import { emptyContext } from "../../../context_empty/context_empty";

function PackageList() {
    const [empty, setEmpty] = useContext(emptyContext);
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [visible, setVisible] = useState(false);
    const [ediPackage, setEdiPackage] = useState(null);
    const [packageList, setPackageList] = useState([]);
    const [sort, setSort] = useState(["id", "DESC"]);
    const [search, setSearch] = useState({
        comment: "",
    });

    InitialParams(setLimit, setCurrentPage, setSearch, setSort);

    const navigate = useNavigate();
    const goToPosts = (params) =>
        navigate({
            search: `?${createSearchParams(params)}`,
        });

    const translation = useSelector(selectTranslation);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
                <Input
                    allowClear
                    autoFocus
                    placeholder="Type text here"
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                    }}
                />
            );
        },
        filterIcon: () => {
            return (
                <>
                    <SearchOutlined />
                </>
            );
        },
    });

    const deleteTable = (id) => {
        const callback = (data) => {
            getPackageList();
        };

        const errorCallback = (data) => { };

        REQUESTS.PACKEGES.DELETE(id, callback, errorCallback);
    };

    const handleMenuClick = (e, item) => {
        switch (e.key) {
            case "delete":
                showPropsConfirm(
                    item.id,
                    deleteTable,
                    loading,
                    "DELETE",
                    "#bf4342",
                    `${translation["Are you sure you want to delete?"] ||
                    TEXT["Are you sure you want to delete?"]
                    }`,
                    `${translation["CANCEL"] || TEXT["CANCEL"]}`
                );
                break;

            case "edit":
                setEdiPackage(item);
                setVisible(true);
                break;

            default:
                break;
        }
    };

    const columns = [
        {
            title: "#",
            width: "60px",
            dataIndex: "id",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },

        {
            title: `${translation["Name"] || TEXT["Name"]}`,
            dataIndex: "name",
            key: "name",
            align: "center",
            ...getColumnSearchProps("name"),
        },
        {
            title: `${translation["Price"] || TEXT["Price"]}`,
            dataIndex: "price",
            sorter: true,
            align: "center",
        },

        {
            title: `${translation["Count"] || TEXT["Count"]}`,
            dataIndex: "count",
            sorter: true,
            align: "center",
        },

        {
            title: "",
            fixed: "right",
            width: "60px",
            dataIndex: "action",
            align: "center",
            render: (text, record, index) => (
                <TableButtons
                    handleMenuClick={(e) => handleMenuClick(e, record)}
                    buttons={[
                        {
                            key: "edit",
                            text: `${translation["Edit"] || TEXT["Edit"]}`,
                            icon: <EditOutlined />,
                        },
                        {
                            key: "delete",
                            text: `${translation["Delete"] || TEXT["Delete"]}`,
                            icon: <DeleteOutlined />,
                        },
                    ]}
                />
            ),
        },
    ];

    const getPackageList = () => {
        setLoading(true);
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
        };

        // if (search.activation_type) {
        //     query.search["activation_type"] = search.activation_type[0];
        //   }

        if (search.name) {
            query.search["name"] = search.name[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        goToPosts(query);

        REQUESTS.PACKEGES.GET(query, (data) => {
            setTotal(data.count);
            // setLimit(data.limit);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setLoading(false);
            setPackageList(data.rows);
            if (!data.rows == []) {
                setEmpty(false);
            }
        });
    };

    //
    useEffect(() => {
        return () => {
            setEmpty(true);
        };
    }, []);

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setSearch(filters);

        if (Object.values(filters)[0]) {
            //   setTitleSearch(Object.values(filters)[0].join(""));
        } else {
            //   setTitleSearch(null);
        }

        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    const onClickAdd = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
        setEdiPackage(null);
    };

    useEffect(() => {
        let timout = setTimeout(() => {
            getPackageList();
        }, 500);
        return () => {
            clearTimeout(timout);
        };
    }, [search, currentPage, limit, sort]);

    const followAddElement = {
        setCurrentPage: setCurrentPage,
        setSearch: setSearch,
        setSort: setSort,
        setCurrentPage: setCurrentPage,
        setLimit: setLimit,
        setTotal: setTotal,
    };

    return (
        <div className={classes["package_list_table"]}>
            <div className={classes["package_list_title"]}>
                <p className={classes["package_title-text"]}>
                    {translation["Credits"] || TEXT["Credits"]}
                </p>
                <Button
                    className={classes["package_add"]}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={onClickAdd}
                />
            </div>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={packageList}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                    // defaultPageSize: 1,
                }}
                scroll={{ x: "max-content" }}
                size="small"
            />

            <PackageListDrawer
                visible={visible}
                onClose={closeDrawer}
                current={ediPackage}
                getData={getPackageList}
                translation={translation}
                followAddElement={followAddElement}
            />
        </div>
    );
}

export default PackageList;
