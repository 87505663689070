import { useState } from "react";

import { DatePicker, Space, Select } from 'antd';
import moment from 'moment';

import DashboardCards from "./components/DashboardCards";
import UsedDevicesProgres from "./components/UsedDevicesProgres";
import PaymentStatistics from "./components/PaymentStatistics";
import ServerStatistics from "./components/ServerStatistics";

import classes from "./index.module.scss";

const PickerWithType = ({ type, onChange, defaultValue }) => {
  if (type === "week") return <DatePicker picker={type} onChange={onChange} defaultValue={moment(new Date(), "MMM Do YY")} format={"YYYY-MM-DD"} />;
  return <DatePicker picker={type} onChange={onChange} defaultValue={defaultValue && moment(defaultValue, "YYYY-MM-DD")} format={"YYYY-MM-DD"} />;
};

function Dashboard() {
  const [date, setDate] = useState(null);

  const [dateType, setDateType] = useState("year");

  return (
    <div>
      <div className={classes['range-picker']}>
        <Space>
          <Select value={dateType} onChange={setDateType}>
            <Select.Option value="year">Year</Select.Option>
            <Select.Option value="month">Month</Select.Option>
            <Select.Option value="week">Week</Select.Option>
          </Select>
          <PickerWithType
            type={dateType}
            onChange={(value) => {
              const date = value ? new Date(value._d) : new Date()
              const year = date.getFullYear();
              const month = date.getMonth();
              const day = date.getDate();

              if (dateType === 'year') {
                setDate(`${year}-01-01`)
              } else if (dateType === 'month') {
                setDate(`${year}-${month < 9 ? '0' + (month + 1) : (month + 1)}-01`)
              } else {
                setDate(`${year}-${month < 9 ? '0' + (month + 1) : (month + 1)}-${day > 9 ? day : '0' + day}`)
              }
            }}
            defaultValue={date}
          />
        </Space>
      </div>

      <DashboardCards date={date} dateType={dateType} />

      <div className={classes["statistics"]}>
        <UsedDevicesProgres dateType={dateType} date={date || new Date().getFullYear() + "-01-01"} />
        <PaymentStatistics dateType={dateType} date={date || new Date().getFullYear() + "-01-01"} />
      </div>

      <ServerStatistics />
    </div>
  );
}

export default Dashboard;
