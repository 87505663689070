import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

export default function TextEditor({ onTranslate, item, }) {
  return item && (
    <ReactQuill
      theme="snow"
      value={item["value"]}
      onChange={onTranslate}
    />
  );
}
