import { useState, useEffect } from "react";

import { Button, Form, InputNumber, Drawer, Select, DatePicker } from "antd";

import dayjs from 'dayjs';

import TEXT from "../../../config/text";

import REQUESTS from "../../../api/requests";

import ErrorMessage from "../../../components/ErrorMessage";

import classes from "../style/activationDrawer.module.scss";

const { Option } = Select;

const ActivationDrawer = ({ onClose, visible, current, getData, translation }) => {

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    const [packages, setPackages] = useState([]);

    const [select, setSelect] = useState(null)

    const [message, setMessage] = useState({
        type: false,
        text: "",
    });

    const getPackages = () => {
        const collback = (data) => {
            setPackages(data.rows);
        };
        REQUESTS.DEVICE.GET_PACKAGES(collback);
    };

    const onValuesChange = (e) => {
        setMessage({
            type: false,
            text: "",
        });
    };

    const onFinish = (values) => {
        const { packages, amount, date } = values;

        setLoading(true);

        const body = {
            id: current.id,
            amount,
        }

        if (date && packages === "Select date") {
            body["activation_date"] = date.format('YYYY-MM-DD');
        }

        if (packages !== "Select date") { 
            body["package_id"] = packages || packages[0]?.id;
        }

        function callback() {
            setLoading(false);
            onClose();
            getData();
        }

        function errorCallback(err) {
            setLoading(false);
            setMessage({ type: false, text: err });
        }

        REQUESTS.DEVICE.ACTIVATE_DEVICE(body, callback, errorCallback);
    };

    useEffect(() => {
        getPackages();
    }, [current]);

    useEffect(() => {
        if (!visible) {
            form.resetFields();

            setLoading(false);

            setSelect(null);

            setMessage({
                type: false,
                text: "",
            });
        }
    }, [visible]);

    return (
        <Drawer
            title={`${translation["Activate"] || TEXT["Activate"]} `}
            placement="right"
            onClose={onClose}
            open={visible}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onValuesChange={onValuesChange}
                initialValues={{
                    packages: packages[0]?.id,
                    amount: 0,
                    date: null,
                }}
            >
                <div style={{ width: "100%" }}>
                    {
                        packages.length &&
                        <Form.Item
                            name="packages"
                            label="Package" >
                            <Select onChange={(value) => setSelect(value)}>
                                {packages.map((item, index) => {
                                    return (
                                        <Option key={index} value={item.id}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                                <Option value="Select date">
                                    Select date
                                </Option>
                            </Select>
                        </Form.Item>
                    }

{
                        select === "Select date" &&
                        <Form.Item
                            label="Expired date"
                            name="date"
                        >
                            <DatePicker
                                style={{ width: "100%" }}
                                disabledDate={(current) => {
                                    // Can not select days before today and today
                                    return current && current < dayjs().endOf('day');
                                }}
                            />
                        </Form.Item>
                    }

                    <Form.Item
                        name='amount'
                        label="Price"
                    >
                        <InputNumber
                            min={0}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>

                    <ErrorMessage message={message} />

                    <Form.Item >
                        <Button
                            type="primary"
                            htmlType="submit"
                            className={classes["save-button"]}
                            loading={loading}
                        >
                            {translation["Save"] || TEXT["Save"]}
                        </Button>
                    </Form.Item>
                </div>
            </Form>
        </Drawer>
    );
};

export default ActivationDrawer;
