import React, { useEffect, useState } from 'react';

import { useSearchParams } from "react-router-dom";

import { useSelector } from 'react-redux';

import { Table, DatePicker, Button, Space, Radio } from 'antd';
import { CalendarOutlined } from "@ant-design/icons";

import classNames from 'classnames';

import { selectTranslation } from '../../../features/Translation/TranslationSlice';

import getMyDate from '../../../components/getMyDate';
import getColumnSearchProps from '../../../components/getColumnSearchProps';

import TEXT from '../../../config/text';

import REQUESTS from '../../../api/requests';

import ResellerInfoDrawer from '../../reseller/components/ResellerInfoDrawer';

import classes from "../style/deviceManagerDraver.module.scss"

const { RangePicker } = DatePicker;

export default function DevicePaymentHistory({ mac }) {
    const [search, setSearch] = useState({});
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [sort, setSort] = useState(["id", "DESC"]);
    const [date, setDate] = useState("");

    const [paymentHistory, setPaymentHistory] = useState([]);

    const [searchParams] = useSearchParams();

    const translation = useSelector(selectTranslation);

    const [showInfo, setShowInfo] = useState(false)

    const columns = [
        {
            title: "#",
            dataIndex: "id",
            fixed: "left",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },
        {
            title: `${translation["Date"] || TEXT["Date"]}`,
            dataIndex: "createdAt",
            key: "createdAt",
            align: "center",
            sorter: true,
            render: (record) => {
                return getMyDate(record);
            },
            filterDropdown: ({ }) => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{
                        color: JSON.parse(searchParams.get("between"))?.createdAt
                            ? "#1890ff"
                            : undefined,
                    }}
                />
            ),
        },
        {
            title: `${translation["Payment method"] || TEXT["Payment method"]}`,
            dataIndex: "method",
            key: "method",
            align: "center",
            ...getColumnSearchProps(),
            render: (text, record, index) => <span className={classNames({ [classes['reseller-pay-txt']]: text == "reseller pay" })}>{text}</span>
        },
        {
            title: `${translation["Status"] || TEXT["Status"]}`,
            dataIndex: "status",
            key: "status",
            align: "center",
            filters: [
                {
                    text: "COMPLETED",
                    value: "COMPLETED",
                },
                {
                    text: "PENDING",
                    value: "PENDING",
                },
                {
                    text: "REFUNDED",
                    value: "REFUNDED",
                },
            ],
            filterDropdown: ({ filters, setSelectedKeys, selectedKeys, confirm }) => {
                return (<>
                    <div style={{ padding: "6px 0 0 6px" }}>
                        <Radio.Group onChange={(e) => setSelectedKeys([e.target.value])} value={selectedKeys[0]} >
                            {
                                filters.map(filteredItem => (
                                    <div><Radio value={filteredItem.value}>{filteredItem.text}</Radio></div>
                                ))
                            }
                        </Radio.Group>

                    </div>
                    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                        <Space>
                            <Button
                                type="link"
                                onClick={() => {
                                    setSelectedKeys([]);
                                    confirm();
                                }}
                                size="small"
                                style={{ width: 90 }}
                            >
                                Reset
                            </Button>

                            <Button
                                size="small"
                                style={{
                                    backgroundColor: "#000",
                                    color: '#fff',
                                }}
                                onClick={() => {
                                    confirm();
                                }}
                            >
                                OK
                            </Button>
                        </Space>
                    </div>
                </>
                )
            }
        },
        {
            title: `${translation["Amount"] || TEXT["Amount"]}`,
            dataIndex: "amount",
            key: "amount",
            align: "center",
            sorter: true,
        },
        {
            title: `${translation["Currency"] || TEXT["Currency"]}`,
            dataIndex: "currency",
            key: "currency",
            align: "center",
            ...getColumnSearchProps(),
        },

        {
            title: `${translation["Package Name"] || TEXT["Package Name"]}`,
            dataIndex: "package_name",
            key: "package_name",
            align: "center",
            ...getColumnSearchProps(),
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
        },

        {
            title: `${translation["Subscription id"] || TEXT["Subscription id"]}`,
            dataIndex: "subscription_id",
            key: "subscription_id",
            align: "center",
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
            ...getColumnSearchProps(),
        },
        {
            title: `${translation["Device model"] || TEXT["Device model"]}`,
            dataIndex: "device_model",
            key: "device_model",
            align: "center",
            ...getColumnSearchProps(),
            render: (record, text, index) => {
                if (record) {
                    return record;
                } else {
                    return "N/A";
                }
            },
        },
    ];

    const getResellerData = (reseller) => {
        if (!reseller.reseller_id) return;

        const query = {
            filter: JSON.stringify({
                id: reseller.reseller_id
            })
        }

        const callback = (response) => setShowInfo(response.rows?.[0] || {});

        REQUESTS.GET_RESELLERS(query, callback)
    }


    const getPaymentHistory = () => {
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {
                "mac": mac
            },

        };

        if (search.status) {
            query.search['status'] = search.status[0]
        }

        if (search.device_model) {
            query.search["device_model"] = search.device_model[0];
        }

        if (search.method) {
            query.search["method"] = search.method[0];
        }

        if (search.currency) {
            query.search["currency"] = search.currency[0];
        }

        if (search.subscription_id) {
            query.search["subscription_id"] = search.subscription_id[0];
        }

        if (search.ip) {
            query.search["ip"] = search.ip[0];
        }

        if (search.package_name) {
            query.search["package_name"] = search.package_name[0];
        }

        if (search.email) {
            query.search["email"] = search.email[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        if (date && date[0]) {
            let to = new Date(date[1]);
            to.setDate(to.getDate() + 1);
            query.between = JSON.stringify({
                createdAt: {
                    from: new Date(date[0]),
                    to: new Date(to),
                },
            });
        }

        REQUESTS.PAYMENTS.GET(query, (data) => {
            setTotal(data.count);
            setLimit(data.limit);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setPaymentHistory(data.rows);

        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setSearch(filters);
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    useEffect(() => {
        let timout = setTimeout(() => {
            if (mac) {
                getPaymentHistory();
            }
        }, 500);
        return () => {
            clearTimeout(timout);
        };
    }, [search, currentPage, limit, date, sort, mac]);

    return (
        <div>
            <p className={classes['device-payments-title']}>Payments</p>
            <Table
                rowKey="id"
                columns={columns}
                dataSource={paymentHistory}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            if (record?.method === "reseller pay") {
                                try {
                                    const resellerId = JSON.parse(record.info) || {};

                                    getResellerData(resellerId);

                                } catch (error) {
                                    console.error(error);
                                }

                            }
                        }
                    };
                }}
                rowClassName={(record, index) => record?.method === "reseller pay" ? classes["reseller_pay"] : ""}
            />
            <ResellerInfoDrawer
                visible={showInfo}
                onClose={() => { setShowInfo(null) }}
                current={showInfo}
            />
        </div>
    )
}
