import { useState, useEffect } from "react";

import {
  get_chat_list,
  view_message_status,
} from "../../requests/realTimechatRequests";

import { useDispatch, useSelector } from "react-redux";
import {
  selectChatList,
  editChatList,
  selectchat,
  selectActivId,
} from "../../../../features/chat/chatSlise";

import RenderChatLis from "./renderChatList";


function ChatList({ setIsShow, setInputValue }) {
  const [count, setCount] = useState(10);

  const chat = useSelector(selectchat);

  const user = useSelector(selectActivId);

  const chatList = useSelector(selectChatList);

  const dispatch = useDispatch();

  const get_chat = () => {
    const getList = (data) => {
      dispatch(editChatList(data));
    };

    let query = {};

    if (count != 0) {
      query.limit = count;
    }

    get_chat_list(getList, query, setCount);
  };

  useEffect(() => {
    get_chat();
  }, [chat]);

  useEffect(() => {
    if (user?.id) {
      view_message_status({ chat_id: user?.id }, get_chat);
    }
  }, [user]);

  useEffect(() => {
    get_chat();
  }, [count]);

  return RenderChatLis(chatList, setIsShow, setInputValue)

}

export default ChatList;
