import { useEffect, useState } from 'react';

import { Drawer, DatePicker, Divider } from 'antd';

import { DashboardOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';

import REQUESTS from '../../api/requests';

import styles from "./index.module.scss"

export default function FreeDNDStatistic({ open, onClose, current }) {
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [year, setYear] = useState();
    const [month, setMonth] = useState();

    const getData = () => {
        if (!current) return;

        const query = {
            free_dns_id: current.id
        };

        if (year && month) {
            query["year"] = year
            query["month"] = month
        }

        REQUESTS.FREE_DNS_STATISTICS(query, (data) => {
            setData(data)
            let generalCount = data.reduce((acc, item) => {
                return acc + item.device_count
            }, 0);

            setTotal(generalCount);

        }, (error) => { })
    }

    const onChange = (date, dateString) => {
        setYear(dateString.split('-')[0])
        setMonth(dateString.split('-')[1])

        if (typeof dateString == 'string' && dateString.length == 0) {
            let date = new Date()
            setYear(date.getFullYear())
            let month = date.getMonth() + 1
            month = month?.length == 1 ? month : '0' + month
            setMonth(month)
        }
    };

    useEffect(() => {
        if (open && current) {
            getData();
        } else {
            setData([]);
            setTotal(null);
            setYear(null);
            setMonth(null);
        }

    }, [current, open]);

    useEffect(() => {
        getData();
    }, [year, month])

    return (
        <Drawer
            open={open}
            onClose={onClose}
            title="Free DNS statistic"
            destroyOnClose={true}
        >
            <DatePicker onChange={onChange}
                picker="month"
                defaultValue={year && month ? dayjs(`${year}-${month}`, 'YYYY-MM') : null}
                placeholder='Select Date'
            />
            {data.length > 0 ? <Divider style={{ margin: "10px 0" }} /> : null}
            <ol className={styles['list']}>
                {data.map((item, index) => (
                    <li key={index}>
                        <strong>{item.model} : </strong>{item.device_count}
                    </li>
                ))}
            </ol>
            {data.length > 0 ? <Divider style={{ margin: "10px 0" }} /> : null}
            <strong>
                <DashboardOutlined /> Total: {total}
            </strong>
        </Drawer>
    )
}
