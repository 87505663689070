import classes from "../../style/messagesContainer.module.scss";
import { selectActivId } from "../../../../features/chat/chatSlise";
import { useSelector } from "react-redux";
import { useRef } from "react";
import { useEffect } from "react";

function MessagesContainer({ messagesData }) {
  const useScrollRef = useRef();
  const user = useSelector(selectActivId);

  const meesages_list = messagesData.map((el) => {
    return (
      <div
        className={
          classes[el?.sender === "user" ? "user_message" : "admin_message"]
        }
      >
        <p style={{ marginBottom: 0 }}>{el?.message}</p>
      </div>
    );
  });

  function handleScrollBack() {
    useScrollRef.current?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  useEffect(() => {
    handleScrollBack();
  }, [messagesData]);

  return (
    user &&
    user.id && (
      <div className={classes["messages_container_active"]}>
        {meesages_list}
        <div ref={useScrollRef}></div>
      </div>
    )
  );
}

export default MessagesContainer;
