import { useState, useEffect } from "react";

import { Button, Form, Input, Drawer, Checkbox, Select } from "antd";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import ErrorMessage from "../../../components/ErrorMessage";

import classes from "../style/createplaylist.module.scss";

const Createplaylist = ({
  onClose,
  visible,
  current,
  getData,
  translation,
  userInfo,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });

  const [isProtected, setIsProtected] = useState(false);

  const [server, setServer] = useState([]);
  const [selected, setSelected] = useState(null);

  const onValuesChange = (changedValues, allValues) => {
    setMessage({
      type: false,
      text: "",
    });

  };

  const onFinish = (values) => {
    const { name, M3U8_url, pin, confirm_pin, password, username } = values

    setLoading(true);

    let body = {
      name: name,
      url: M3U8_url
    };

    if (current) {
      body["id"] = current.id;
      
      body["is_protected"] = isProtected;
      
      if (isProtected) {
        body["pin"] = values.pin;
        body["confirm_pin"] = values.confirm_pin;
      }

    } else {
      body["deviceId"] = userInfo.id;

      if (isProtected) {
        body["pin"] = pin;
        body["confirm_pin"] = confirm_pin;
        body["is_protected"] = isProtected;
      }
    }
    if (server && selected && selected !== "custom") {

      let selectedServer = server.find((item) => item.id === selected)

      if (selectedServer && username && password) {
        const url = `${selectedServer.host}/get.php?username=${username.trim()}&password=${password.trim()}`

        body["url"] = url
      }
    }

    function callback() {
      setLoading(false);
      onClose();
      getData();
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    if (current) {
      REQUESTS.DEVICE.EDIT_PLAYLIST(body, callback, errorCallback);
    } else {
      REQUESTS.DEVICE.ADD(body, callback, errorCallback);
    }
  };

  const getServer = () => {

    function callback(data) {
      setServer(data.rows);
    }

    REQUESTS.GET_SERVER(callback)
  }

  useEffect(() => {
    if (current) {
      form.setFields([
        {
          name: "name",
          value: current.name,
        },

        {
          name: "M3U8_url",
          value: current.url,
        },
        {
          name: "pin",
          value: current.pin,
        },
        {
          name: "confirm_pin",
          value: current.pin,
        },

      ]);

      setIsProtected(current.is_protected);
    } else {
      form.resetFields();
      setIsProtected(false);
    }

  }, [current, visible]);

  useEffect(() => {
    if (visible) {
      getServer()
    } else {
      setLoading(false);
      onValuesChange();
      setSelected("custom")
    }
  }, [visible]);

  return (
    <Drawer
      title={
        current
          ? translation["Edit Playlist"] || TEXT["Edit Playlist"]
          : translation["Add Playlist"] || TEXT["Add Playlist"]
      }
      placement="right"
      onClose={onClose}
      open={visible}
    >
      <Form
        form={form}
        name="profile"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        initialValues={{
          password: "",
        }}
      >
        {
          server && server.length > 0 && <Form.Item
            name="server_id"
            label={translation["DNS"] || TEXT["DNS"]}
          >
            <Select
              className={classes["select"]}
              onChange={(value) => {
                setSelected(value);
                form.resetFields()
              }}
              defaultValue={selected}
            >
              <Select.Option value={"custom"}>Custom</Select.Option>

              {
                server?.map((item, index) => {
                  return <Select.Option key={index} value={item.id}>
                    {item.name}
                  </Select.Option>
                })
              }
            </Select>
          </Form.Item>
        }
        <Form.Item
          label={translation["Name"] || TEXT["Name"]}
          name="name"
          rules={[
            {
              required: true,
              message: "Please input name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        {
          selected === "custom" ? <Form.Item
            label={translation["M3U8 URL"] || TEXT["M3U8 URL"]}
            name="M3U8_url"
            rules={[
              {
                required: true,
                message: "Please input M3U8 url",
              },
            ]}
          >
            <Input />
          </Form.Item>
            : <>
              <Form.Item
                label={translation["username"] || TEXT["username"]}
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input username",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={translation["Password"] || TEXT["Password"]}
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input password",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
            </>
        }

        <Checkbox
          checked={isProtected}
          onChange={(e) => setIsProtected(e.target.checked)}
          className={classes["protected-playlist-checkbox"]}
        >
          {translation["Protect Playlist"] || TEXT["Protect Playlist"]}
        </Checkbox>

        <Form.Item
          name="pin"
          label={translation["Set PIN"] || TEXT["Set PIN"]}
          rules={[
            {
              required: current && current.is_protected ? false : isProtected,
              message: "Please input your password!",
            },
          ]}
        >
          <Input.Password
            disabled={!isProtected}
          />
        </Form.Item>

        <Form.Item
          name="confirm_pin"
          dependencies={["pin"]}
          label={translation["Confirm PIN"] || TEXT["Confirm PIN"]}
          rules={[
            {
              required: current && current.is_protected ? false : isProtected,
              message: "Please confirm your password!",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("pin") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The two passwords that you entered do not match!")
                );
              },
            }),
          ]}
        >
          <Input.Password
            disabled={!isProtected}
          />
        </Form.Item>

        <ErrorMessage message={message} />
        <div className={classes["playlist_save_div"]}>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => form.submit()}
              className={classes["playlist_save"]}
              loading={loading}
            >
              {translation["Save"] || TEXT["Save"]}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  );
};

export default Createplaylist;
