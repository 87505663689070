import PackageList from "./components/PackageList";
import StatusList from "./components/StatusList";
import classes from "./index.module.scss";

function PackagePage() {
  return (
    <div>
      <PackageList />
    </div>
  );
}

export default PackagePage;
