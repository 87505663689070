import React from "react";

import { Checkbox, Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import { CSS } from "@dnd-kit/utilities";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function DndContextComonent({
  setCategory,
  category,
  setCategoryIds,
  categoryIds,
}) {
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setCategory((previous) => {
        const activeIndex = previous.findIndex(
          (i) => i.category_id === active.id
        );
        const overIndex = previous.findIndex((i) => i.category_id === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <div className="xtream-palylists-categories__DndContext">
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          items={category.map((item) => {
            return item.category_id;
          })}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="category_id"
            scroll={{
              y: 500,
            }}
            pagination={false}
            showHeader={false}
            columns={[
              {
                key: "sort",
                width: 70,
              },
              {
                title: "Name",
                dataIndex: "category_name",
                render: (text, item) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>{item.category_name}</div>
                      <div>
                        <Checkbox
                          checked={categoryIds.includes(item.category_id)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              setCategoryIds([
                                ...categoryIds,
                                item.category_id,
                              ]);
                            } else {
                              setCategoryIds(
                                categoryIds.filter(
                                  (id) => id !== item.category_id
                                )
                              );
                            }
                          }}
                        />
                      </div>
                    </div>
                  );
                },
              },
            ]}
            size="small"
            dataSource={category}
          />
        </SortableContext>
      </DndContext>
    </div>
  );
}
