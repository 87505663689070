import classes from "../../style/sendMessage.module.scss";

import { useDispatch } from "react-redux";

import React from "react";
import { Button, Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useState, useEffect } from "react";
import { Send_message } from "../../requests/realTimechatRequests";
import { get_chat_list } from "../../requests/realTimechatRequests";

import { editChatList } from "../../../../features/chat/chatSlise";
const { TextArea } = Input;

function SendMessage({ activeUser, get_messages, inputValue, setInputValue }) {
  // const [inputValue, setInputValue] = useState("");
  const [enterState, setEnterState] = useState(false);

  const dispatch = useDispatch();

  const send_message_form = () => {
    const body = {
      message: inputValue,
      chat_id: activeUser?.id,
    };

    Send_message(body, () => {
      get_messages();
      get_chat_list((data) => {
        dispatch(editChatList(data));
      },{limit:10000000})
    }, setInputValue);
  };

  useEffect(() => {
    const keyDownHandler = (event) => {
      if (event.key === "Enter" && event.key !== "Shift") {
        event.preventDefault();
        setEnterState(!enterState);
      }
    };

    document.addEventListener("keydown", keyDownHandler);

    return () => {
      document.removeEventListener("keydown", keyDownHandler);
    };
  }, [enterState]);

  useEffect(() => {
    if (inputValue.trim().length > 0) {
      setInputValue("");
      send_message_form();
    }
  }, [enterState]);

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
      }}
      className={
        classes[activeUser?.id ? "send_message_form" : "send_message_form_none"]
      }
    >
      <textarea
        className={classes["send_message_input"]}
        placeholder="Enter Message"
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        value={inputValue}
        type="textarea"
      />

      <SendOutlined
        style={{color:"#4ce147"}}
        className={classes["send_message_button"]}
        onClick={send_message_form}
      />
    </form>
  );
}

export default SendMessage;
