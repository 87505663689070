import React, { useEffect, useState } from 'react';

import { Form, Input, Button,notification } from 'antd';

import REQUESTS from '../../../api/requests';

import ErrorMessage from '../../../components/ErrorMessage';

export default function SubtitlePage() {
    const [form] = Form.useForm();

    const [data, setData] = useState({});

    const [message, setMessage] = useState({
        type: false,
        text: "",
      });

    const onFinish = (values) => { 
        setMessage({ type: false, text: "" });

        const body = {
            id: data.id,
            ...values
        }
        
        REQUESTS.SUBTITLE.PUT(body, () => {
            notification.open({
                message: "Updated",
                duration: 5,
              });
        
            getData();
        }, (error) => { 
            setMessage({ type: false, text:error });
        })
    }

    const getData = () => {
        REQUESTS.SUBTITLE.GET((data) => {
            setData(data);

            form.setFields([{
                name: 'username',
                value: data.username
            }, {
                name: 'password',
                value: data.password
            }, {
                name: 'api_key',
                value: data.api_key
            }])
        })
    }

    useEffect(() => {
        getData();
    }, []);

    return (
        <div>
              <h3>Subtitles</h3>
            <Form
                layout='vertical'
                form={form}
                onFinish={onFinish}
                style={{ maxWidth: 300 }}
            >
                <Form.Item
                    label="Username"
                    name="username"
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    label="Api key"
                    name="api_key"
                >
                    <Input />
                </Form.Item>
                <ErrorMessage message={message} />
                <Form.Item style={{textAlign:"center"}}>
                    <Button type="primary" htmlType='submit'>Save</Button>
                </Form.Item>
            </Form>
        </div>
    )
}
