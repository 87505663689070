import { useSelector } from "react-redux";

import { LeftOutlined } from "@ant-design/icons";
import classes from "../../style/userInfo.module.scss";
import { selectActivId } from "../../../../features/chat/chatSlise";

function MessageSenderInfo({ isShow, setIsShow }) {
  const user = useSelector(selectActivId);
  return (
    <h1 className={classes[user?.id ? "user_info" : "user_info_none"]}>
      {isShow && (
        <LeftOutlined
          onClick={() => setIsShow(false)}
          className={classes["message-chat-back-button"]}
        />
      )}
      {user?.mac}
    </h1>
  );
}

export default MessageSenderInfo;
