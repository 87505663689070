import classes from "../style/languagesDraver.module.scss";
import REQUESTS from "../../../api/requests";
import { PlusOutlined } from "@ant-design/icons";
import { useState, useEffect, useContext } from "react";
import { emptyContext } from "../../../context_empty/context_empty";
import AddTranslation from "./AddTranslation";
import TEXT from "../../../config/text";
import ActionButton from "../../../components/ActionButton";
import { Drawer, Table, Input, notification, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";

const LanguagesDraver = ({
  onClose,
  visible,
  translationsData,
  translation,
}) => {
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [value, setValue] = useState();
  const [translationVisible, setTranslationVisible] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const getTranslations = () => {
    setLoading(true);
    function collback(data) {
      setLanguages(data.rows);
      setLoading(false);

    }

    function errorCollback(data) { }
    REQUESTS.LENG.WORDS(collback, errorCollback, {
      filter: JSON.stringify({ language_id: translationsData?.id }),
      limit: 2000,
      sort: JSON.stringify(["key", "ASC"]),
    });
  };

  useEffect(() => {
    if (visible === true) {
      getTranslations();
    }
  }, [visible]);

  const onClickAdd = () => {
    setTranslationVisible(true);
  };

  const translationClose = () => {
    setTranslationVisible(false);
    setValue(null);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "50px",
      key: "id",
      render: (text, record, index) => {
        return index + 1;
      },
    },

    {
      title: ` ${translation["Key"] || TEXT["Key"]}`,
      dataIndex: "key",
      width: "350px",
      key: "key",
    },

    {
      title: ` ${translation["Value"] || TEXT["Value"]}`,
      width: "350px",
      dataIndex: "value",
      key: "value",
    },

    {
      title: "",
      width: "50px",
      fixed: "right",
      render: (text, record, index) => (
        <ActionButton
          handleMenuClick={(e) => {
            onClickAdd();
            setValue(text);
          }}
          text={translation["Edit"] || TEXT["Edit"]}
          index={index}
          icon={<EditOutlined />}
        />
      ),
    },
  ];

  useEffect(() => {
    const changeWindowWidth = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", changeWindowWidth);

    return () => window.removeEventListener("resize", changeWindowWidth);
  }, []);

  return (
    <Drawer
      title={translationsData?.name}
      placement="right"
      onClose={onClose}
      open={visible}
      width={windowWidth > 880 ? 500 : "85vw"}
    >
      {/* <div className={classes["leng_list_title"]}>
        <Button type="primary" icon={<PlusOutlined />} onClick={onClickAdd} />
      </div> */}
      <AddTranslation
        getTranslations={getTranslations}
        translationClose={translationClose}
        translationVisible={translationVisible}
        current={value}
      />
      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={languages}
        pagination={false}
        size="small"
      />
    </Drawer>
  );
};

export default LanguagesDraver;
