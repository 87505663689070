import { useState, useEffect } from "react";

import { Button, Drawer, Select, Spin, Form } from "antd";

import REQUESTS from "../../../api/requests";

import ErrorMessage from "../../../components/ErrorMessage";

let timeout = null;

export default function TransferReseller({ open, onClose, current = {} }) {
    const [options, setOptions] = useState([]);

    const [message, setMessage] = useState({
        type: false,
        text: "",
    });

    const [form] = Form.useForm()

    async function fetchOptions(username) {
        const query = {
            search: {}
        };

        query.search["email"] = username || "";

        query["search"] = JSON.stringify(query["search"]);

        REQUESTS.GET_RESELLERS(query, (data) => {
            let newData = data.rows.filter(item => item.id !== current.id)
                .map((reseller) => ({ label: reseller.email, value: reseller.email, id: reseller.id }));
            setOptions(newData);
        })
    }

    const onChange = (value) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fetchOptions(value)
        }, 1000)
    }

    const onFinish = (values) => {
        setMessage({
            type: false,
            text: "",
        });

        const { email } = values;

        const body = {
            from: current.id
        };

        if (email) {
            options.find((option) => {
                if (option.value === email) {
                    body.to = option.id
                }
            })
        }

        function callback(data) {
            onClose();
        }

        function erroCallback(error) {
            setMessage({
                type: false,
                text: error,
            })
        }

        REQUESTS.TRANSFER_RESSELLER(body, callback, erroCallback)
    }

    useEffect(() => {
        if (open) {
            fetchOptions();
        } else {
            form.resetFields()
        }
    }, [open]);

    useEffect(() => {
        if (open && current?.parent_id) {
            REQUESTS.GET_RESELLERS({ filter: JSON.stringify({ id: current.parent_id }) }, (data) => {
                if (data.rows[0]) {
                    form.setFields([
                        {
                            name: "email",
                            value: data.rows[0].email,
                        },])
                }
            }, (error) => { });
        }

    }, [current, open]);

    return (
        <Drawer
            title="Transfer Reseller"
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form
                form={form}
                onFinish={onFinish}
                layout="vertical"
            >
                <Form.Item
                    name="email"
                    rules={[{
                        required: true,
                        message: 'Please select user',
                    },]
                    }
                    label="Parent Reseller"
                >
                    <Select
                        showSearch
                        placeholder="Select users"
                        defaultActiveFirstOption={false}
                        onSearch={onChange}
                        onSelect={onChange}
                        style={{
                            width: '100%',
                        }}
                        notFoundContent={options.length ? <div style={{ textAlign: "center" }}>
                            <Spin size="small" /></div> :
                            null}
                        options={options}
                    />
                </Form.Item>
                <ErrorMessage message={message} />
                <Form.Item style={{ textAlign: "center" }}>
                    <Button htmlType="submit" type="primary">Save</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
