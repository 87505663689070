import { useState, useEffect } from "react";
import { get_messages } from "../../requests/realTimechatRequests";
import { useSelector } from "react-redux";
import { selectActivId, selectchat } from "../../../../features/chat/chatSlise";
import MessageSenderInfo from "./MessageSenderInfo";
import MessagesContainer from "./MessagesContainer";
import SendMessage from "./SendMessage";
import { selectappInfo } from "../../../../features/appInfoSlice/AppInfoSlice";

import classes from "../../style/messenger.module.scss";

function Messenger({ isShow, setIsShow ,inputValue,setInputValue}) {
  const activeUser = useSelector(selectActivId);
  const newChat = useSelector(selectchat);
  const appInfo = useSelector(selectappInfo);

  const [messagesData, setMessagesData] = useState([]);

  function get_messages_by_id() {
    const query = {
      chat_id: activeUser?.id,
      limit: 10,
    };
    
    get_messages(setMessagesData, query);
  }

  useEffect(() => {
    if (activeUser?.id) {
      get_messages_by_id();
    }
  }, [activeUser?.id, newChat]);

  return activeUser && activeUser.id ? (
    <div className={classes["messenger_container_active"]}>
      <MessageSenderInfo isShow={isShow} setIsShow={setIsShow} />
      <MessagesContainer messagesData={messagesData} />
      <SendMessage
        activeUser={activeUser}
        get_messages={get_messages_by_id}
        inputValue={inputValue}
        setInputValue={setInputValue}
      />
    </div>
  ) : (
    appInfo && (
      <div
        className={classes["messenger_container"]}
        style={{
          backgroundImage: `url(${appInfo.logo})`,
        }}
      ></div>
    )
  );
}

export default Messenger;
