import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { Form } from 'antd';

import TEXT from '../config/text';

import REQUESTS from '../api/requests';

import classes from "./style/macMaskComponentStyle.module.scss";

export default function MacMaskComponent({ value, setValue, setDevice, setMacIsValid, placeholder }) {
    const translation = useSelector((state) => state.translationData.translation);

    const validateMacAddress = () => {
        if (value?.length == 0) return;

        function callback(data) {
            if (setDevice) setDevice(data);
            if (setMacIsValid) setMacIsValid(true);
        }

        function errorCallback(error) {
            if (setMacIsValid) setMacIsValid(false);
        }

        REQUESTS.VALIDATE_MAC(value, callback, errorCallback);
    };

    const generateMacFormat = (e) => {
        var mac = e.target.value.replace(/[^0-9a-z]/gi, "");

        var result = "";

        for (var i = 0; i < mac.length; i++) {
            if (i % 2 === 0 && i !== 0) {
                result += ":";
            }
            result += mac[i];
        }
        setValue(result.substring(0, 17));
    };

    useEffect(() => {
        if (value?.length === 17) validateMacAddress();
    }, [value]);

    return <Form.Item label={placeholder ? null : translation["Mac"] || TEXT["Mac"]} >
        <input
            value={value}
            onChange={generateMacFormat}
            name="mac"
            style={{ width: "100%" }}
            className={classes['mac-inp']}
            placeholder={placeholder}
        />
    </Form.Item>
}
