import { useEffect, useState } from "react";

import { Drawer, Form, Input, Button, Checkbox } from 'antd';
import REQUESTS from "../../../api/requests";
import ErrorMessage from "../../../components/ErrorMessage";

export default function MigrateDns({ open, onClose, getData }) {
    const [form] = Form.useForm();

    const [checked, setChecked] = useState(false);

    const [message, setMessage] = useState({ text: "", type: false });

    const onFinish = (values) => {

        setMessage({ text: "", type: false });

        const body = {
            ...values
        };

        function callback() {
            onClose();
            getData();
            form.resetFields();
        };

        function errorCallback(err) {
            setMessage({ text: err, type: false });
        };

        REQUESTS.MIGRATE_DNS(body, callback, errorCallback);
    }

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setMessage({ text: "", type: false });
        }
    }, [open])

    return (
        <Drawer
            title="Migrate DNS"
            open={open}
            onClose={onClose}
            destroyOnClose={true}
        >
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
            >
                <Form.Item
                    rules={[
                        { required: true, message: 'Please input Dns!' },
                        {
                            validator: (rule, value, callback) => {
                                const urlPattern = /^(ftp|http|https):\/\/[^ "\/]+$/;
                                if (!value || urlPattern.test(value)) {
                                    callback();
                                } else {
                                    callback('Please enter a valid URL without a trailing slash!');
                                }
                            }
                        },
                    ]}
                    label="Old DNS"
                    name="from"
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    rules={[
                        { required: true, message: 'Please input Dns!' },
                        {
                            validator: (rule, value, callback) => {
                                const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
                                if (!value || urlPattern.test(value)) {
                                    callback();
                                } else {
                                    callback('Please enter a valid URL!');
                                }
                            }
                        },
                    ]}
                    label="New DNS"
                    name="to"
                >
                    <Input />
                </Form.Item>

                <Checkbox
                    checked={checked}
                    onChange={() => setChecked(!checked)}
                    style={{ marginBottom: 20 }}
                >
                    I confirm that I want to migrate the DNS
                </Checkbox>

                <ErrorMessage message={message} />
                <Form.Item style={{ textAlign: "center" }} >
                    <Button type="primary" htmlType="submit" disabled={!checked}>Save</Button>
                </Form.Item>
            </Form>
        </Drawer >
    )
}
