import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Modal, DatePicker, Input, Popover } from "antd";
import classes from "./index.module.scss";
import TEXT from "../../config/text";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { emptyContext } from "../../context_empty/context_empty";
import {
  CheckCircleOutlined,
  CalendarOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import getMyDate from "../../components/getMyDate";
import InitialParams from "../../components/InitialParams";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
const { RangePicker } = DatePicker;
const { confirm } = Modal;

function ReferralLinks() {
  const [empty, setEmpty] = useContext(emptyContext);
  const [searchParams] = useSearchParams();
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [referralLinks, setReferralLinks] = useState([]);
  const [date, setDate] = useState("");
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "60px",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      key: "name_res",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Referral"] || TEXT["Referral"]}`,
      dataIndex: "reseller",
      key: "name",
      align: "center",
      render: (record, text, index) => {
        if (record?.name && record?.surname) {
          return <p>{`${record?.name} ${record?.surname}`}</p>;
        } else {
          return <p>N/A</p>;
        }
      },
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Code"] || TEXT["Code"]}`,
      dataIndex: "code",
      key: "code",
      align: "center",
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Clicks"] || TEXT["Clicks"]}`,
      dataIndex: "clicks",
      key: "clicks",
      sorter: true,
      align: "center",
    },

    {
      title: `${translation["Description"] || TEXT["Description"]}`,
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (record, text, index) => {
        if (record) {
          const content = record;
          return (
            <Popover content={content}>
              <div className={classes["ref-link"]}>{record}</div>
            </Popover>
          );
        } else {
          return "N/A";
        }
      },
      ...getColumnSearchProps(),
    },

    {
      title: `${translation["Date"] || TEXT["Date"]}`,
      dataIndex: "createdAt",
      sorter: true,
      align: "center",
      render: (record) => {
        return getMyDate(record);
      },
      filterDropdown: ({}) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => (
        <CalendarOutlined
          style={{
            color: JSON.parse(searchParams.get("between"))?.createdAt
              ? "#1890ff"
              : undefined,
          }}
        />
      ),
    },
  ];

  const getReferralLinks = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.name_res) {
      query.search["name"] = search.name_res[0];
    }

    if (search.name) {
      query.search["$reseller.name$"] = search.name[0];
    }

    if (search.description) {
      query.search["description"] = search.description[0];
    }

    if (search.code) {
      query.search["code"] = search.code[0];
    }

    if (search.reseller) {
      query.search["reseller"] = search.reseller[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    if (date && date[0]) {
      let to = new Date(date[1]);
      to.setDate(to.getDate() + 1);
      query.between = JSON.stringify({
        createdAt: {
          from: new Date(date[0]),
          to: new Date(to),
        },
      });
    }

    goToPosts(query);

    REQUESTS.REFERRAL_LINKS.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setLoading(false);
      setReferralLinks(data.rows);

      if (!data.rows == []) {
        setEmpty(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      setEmpty(true);
    };
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    // if (sorter.field) {
    //   setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    //}
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
    if (Object.values(filters)[0]) {
      //   setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      //   setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getReferralLinks();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, date, sort]);

  return (
    <div className={classes["ReferralLinks_list_table"]}>
      <div className={classes["ReferralLinks_list_title"]}>
        <p className={classes["ReferralLinks_title-text"]}>
          {/* <CheckCircleOutlined />{" "} */}
          {translation["Referral links"] || TEXT["Referral links"]}
        </p>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={referralLinks}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />
    </div>
  );
}

export default ReferralLinks;
