import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { Table, Button } from "antd";

import { PlusOutlined, } from "@ant-design/icons";

import TEXT from "../../config/text";
import REQUESTS from "../../api/requests";
import { getColumnSearchProps } from "../../utils/index";
import MyIcon from "../../components/myicon";
import ICONS from "../../config/icons";
import { selectwidgets } from "../../features/widgets/selector";

import TableButtons from "../../components/TableButtons";
import showPropsConfirm from "../../components/showPropsConfirm";
import ResRefDrawer from "../referralReseller/components/ResRefDrawer";

import "./referral.scss";
import getMyDate from "../../components/getMyDate";

export default function Referral() {
  const [total, setTotal] = useState(10);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    comment: "",
  });
  const [visible, setVisible] = useState(false);
  const [editableRferral, setEditableReferral] = useState(null);

  const [referralsData, setReferralsdata] = useState([]);
  const translation = useSelector((state) => state.translationData.translation);
  const [statusData, setStatusData] = useState([]);
  const widgets = useSelector(selectwidgets);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "60px",
      align: "center",
      render: (text, record, index) => index + 1,
    },
    {
      title: `${translation["Name"] || TEXT["Name"]}`,
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Email"] || TEXT["Email"]}`,
      dataIndex: "email",
      key: "email",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Total links"] || TEXT["Total links"]}`,
      dataIndex: "referral_links",
      key: "referral_links",
      align: "center",
      render: (text, record, index) => {
        if (record) {
          return record.referral_links?.length;
        }
      },
    },
    {
      title: `${translation["Total earnings"] || TEXT["Total earnings"]}`,
      dataIndex: "referral_earnings_total",
      key: "referral_earnings_total",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Balance"] || TEXT["Balance"]}`,
      dataIndex: "balance",
      key: "balance",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Balance on hold"] || TEXT["Balance on hold"]}`,
      dataIndex: "balance_on_hold",
      key: "balance_on_hold",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Status"] || TEXT["Status"]}`,
      dataIndex: "referral_status",
      key: "referral_status",
      align: "center",
      render: (text, record, index) => {
        if (record && record.referral_status && record.referral_status.name) {
          return record.referral_status.name;
        }
      },
      filters: statusData,
    },
    {
      title: `${translation["Created date"] || TEXT["Created date"]}`,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        if (text) {
          return getMyDate(text);
        }
      },

    },
    {
      title: "",
      dataIndex: "actions",
      fixed: "right",
      align: "center",
      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "edit",
              text: `${translation["Edit"] || TEXT["Edit"]}`,
              icon: (
                <div>
                  <MyIcon children={ICONS.PEN} />
                </div>
              ),
            },
            {
              key: "disable",
              text: referralsData[index]?.is_disabled
                ? `${translation["Enable"] || TEXT["Enable"]}`
                : `${translation["Disable"] || TEXT["Disable"]}`,
              icon: referralsData[index]?.is_disabled ? (
                <div>
                  <MyIcon children={ICONS.Lock} />
                </div>
              ) : (
                <div>
                  <MyIcon children={ICONS.PADLOCK} />
                </div>
              ),
            },
          ]}
        />
      ),
    },
  ];

  const getReferrals = () => {
    setLoading(true);

    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
    };

    if (!query.search) {
      query.search = {};
    }
    if (search.name) {
      query.search["name"] = search.name[0];
    }

    if (search.email) {
      query.search["email"] = search.email[0];
    }

    if (search && search.referral_status && search.referral_status.length > 0) {
      query.filter = {};
      query.filter["referral_status_id"] = search.referral_status;
      query.filter = JSON.stringify(query.filter);
    }

    query.search = JSON.stringify(query.search);

    REQUESTS.GET_REFERRALS(
      query,
      (data) => {
        setLoading(false);
        setTotal(data.count);
        setLimit(data.limit);
        setReferralsdata(data.rows);
      },
      (err) => setLoading(false)
    );
  };

  const getStatuses = () => {
    REQUESTS.STATUS.GET({}, (data) => {
      const statusName = data.rows?.map((data) => ({
        text: data.name,
        value: data.id,
      }));
      setStatusData(statusName);
    });
  };

  const disableReseller = (item) => {
    const callback = () => {
      getReferrals();
    };

    const errorCallback = (data) => { };

    REQUESTS.REFERRAL_RESELLER.DISABLE(item.id, callback, errorCallback);
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "disable":
        showPropsConfirm(
          item,
          disableReseller,
          loading,
          `${translation["OK"] || TEXT["OK"]}`,
          "#bf4342",
          item.is_disabled
            ? `${translation["Are you sure you want to enable?"] ||
            TEXT["Are you sure you want to enable?"]
            }`
            : `${translation["Are you sure you want to disable?"] ||
            TEXT["Are you sure you want to disable?"]
            }`,
          `${translation["CANCEL"] || TEXT["CANCEL"]}`
        );
        break;

      case "edit":
        setEditableReferral(item);
        setVisible(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getReferrals();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort]);

  useEffect(() => {
    getStatuses();
  }, []);

  return (
    <div className="referral-page-container">
      {((widgets && !widgets.referral) ||
        (widgets &&
          widgets.referral &&
          widgets.referral.enabled &&
          widgets.referral.enabled === "false")) && (
          <>
            <div className="pro-widget-warning-text">
              To use this feature you need to upgrade to Pro Version
              <br />
              Please contact us for more details.
            </div>
            <div className="pro-widget-tab-layer"></div>
          </>
        )}

      <p className={"referral-top_title"}>
        {translation["Referral"] || TEXT["Referral"]}
      </p>

      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => setVisible(true)}
        className="referral-page_add-edit-btn"
      />

      <Table
        loading={loading}
        rowKey="id"
        rowClassName={(record, index) =>
          referralsData[index].is_disabled ? "is_disabled" : ""
        }
        columns={columns}
        dataSource={referralsData}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />
      <ResRefDrawer
        visible={visible}
        onClose={() => {
          setVisible(false);
          setEditableReferral(null);
        }}
        current={editableRferral}
        getData={getReferrals}
        followAddElement={{
          setCurrentPage,
          setSearch,
          setSort,
          setLimit,
          setTotal,
        }}
        type="referral"
      />
    </div>
  );
}
