import React, { useState, useEffect, memo } from "react";
import { Drawer } from "antd";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";
import ResellerActivationHistory from "./ResellerActivationHistory";
import ResellerSubresellers from "./ResellerSubresellers";
import REQUESTS from "../../../api/requests";

function ResellerInfoDrawer({ visible, onClose, current }) {
  const translation = useSelector(selectTranslation);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [parentReseller, setParentReseller] = useState(null);

  const getParentReseller = () => {
    if (!current.parent_id) return;

    const callback = (response) => {
      setParentReseller(response)
    }

    REQUESTS.PARENT_RESELLER({ parent_id: current.parent_id }, callback)
  }

  useEffect(() => {
    if (!current && !visible) {
      setParentReseller(null);
      return;
    }

    getParentReseller();

    const changeWindowWidth = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", changeWindowWidth);

    return () => window.removeEventListener("resize", changeWindowWidth);

  }, [current, visible]);

  return (
    <Drawer
      title={translation["Reseller info"] || TEXT["Reseller info"]}
      placement="right"
      onClose={onClose}
      open={visible}
      width={windowWidth > 1020 ? 950 : "83vw"}
      destroyOnClose={true}
    >
      <div>
        {current && (
          <ul className="reseller-info-drawer-list">
            <li>
              {translation["Name"] || TEXT["Name"]} :{" "}
              {current.name} {current.surname}
            </li>
            <li>
              {translation["Email"] || TEXT["Email"]} :{" "}
              {current.email}
            </li>
            <li>
              {translation["Total Activations"] || TEXT["Total Activations"]} :{" "}
              {current.total_activations}
            </li>
            <li>
              {translation["Activated devices"] || TEXT["Activated devices"]} :{" "}
              {current.devices && current.devices.length}
            </li>
            <li>{translation["IP address"] || TEXT["IP address"]} :{" "}{current.ip}</li>
            <li>
              {translation["Subresellers count"] || TEXT["Subresellers count"]}{" "}
              : {current.subresellers_count}
            </li>
            <li>Parent reseller: {parentReseller ? `${parentReseller.name} ${parentReseller.surname}` : "N/A"}</li>
            <li>Parent reseller email: {parentReseller ? parentReseller.email : "N/A"}</li>
            <li>Parent reseller id: {parentReseller ? parentReseller.id : "N/A"}</li>
          </ul>
        )}
      </div>

      <ResellerActivationHistory current={current} />
      <ResellerSubresellers current={current} />
    </Drawer>
  );
}

export default memo(ResellerInfoDrawer);