import REQUESTS from "../../api/requests";
import { useEffect, useState } from "react";
import { Table, Button, DatePicker, Input, Empty } from "antd";
import classes from "./index.module.scss";
import BlacklistDrawer from "./components/BlackListDrawer";
import ActionButton from "../../components/ActionButton";
import {
    PlusOutlined,
    DeleteOutlined,
    CalendarOutlined,
    SearchOutlined,
} from "@ant-design/icons";
import getMyDate from "../../components/getMyDate";
import TEXT from "../../config/text";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import { useSelector } from "react-redux";
import showPropsConfirm from "../../components/showPropsConfirm";
import InitialParams from "../../components/InitialParams";
import { useNavigate, createSearchParams, useSearchParams } from "react-router-dom";
import macMaskInput from "../../components/macMaskInput";
const { RangePicker } = DatePicker;

function Blacklist({ chenge, rezet }) {
    const [searchParams] = useSearchParams();
    const [total, setTotal] = useState();
    const [limit, setLimit] = useState(10);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [visible, setVisible] = useState(false);
    const [ediPackage, setEdiPackage] = useState(null);
    const [blacklist, setBlacklist] = useState([]);
    const [date, setDate] = useState("");
    const [sort, setSort] = useState(["id", "DESC"]);
    const [search, setSearch] = useState({
        comment: "",
    });

    InitialParams(setLimit, setCurrentPage, setSearch, setSort);

    const navigate = useNavigate();
    const goToPosts = (params) =>
        navigate({
            search: `?${createSearchParams(params)}`,
        });

    const translation = useSelector(selectTranslation);

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
            return (
                <Input
                    allowClear
                    autoFocus
                    placeholder="Type text here"
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : []);
                        confirm({ closeDropdown: false });
                    }}
                />
            );
        },
        filterIcon: () => {
            return (
                <>
                    <SearchOutlined />
                </>
            );
        },
    });

    const deleteTable = (id) => {
        const callback = (data) => {
            getBlacklist();
        };

        const errorCallback = (data) => { };

        REQUESTS.BLACKLIST.DELETE(id, callback, errorCallback);
    };

    const columns = [
        {
            title: "#",
            align: "center",
            render: (text, record, index) => {
                if (currentPage === 1) {
                    return index + 1;
                } else {
                    return limit * (currentPage - 1) + index + 1;
                }
            },
        },

        {
            title: `${translation["Mac"] || TEXT["Mac"]}`,
            dataIndex: "mac",
            width: "200px",
            key: "mac",
            align: "center",
            render: (record, text, index) => {
                return record ? record : "N/A";
            },

            ...macMaskInput(),
        },
        {
            title: `${translation["Device id"] || TEXT["Device id"]}`,
            dataIndex: "deviceId",
            key: "deviceId",
            width: "150px",
            align: "center",
            ...getColumnSearchProps("deviceId"),
            render: (record, text, index) => {
                return record ? record : "N/A";
            },
        },

        {
            title: `${translation["Ip"] || TEXT["Ip"]}`,
            dataIndex: "ip",
            width: "200px",
            key: "deviceId",
            align: "center",
            ...getColumnSearchProps("deviceId"),
            render: (record, text, index) => {
                return record ? record : "N/A";
            },
        },

        {
            title: `${translation["Date"] || TEXT["Date"]}`,
            width: "200px",
            dataIndex: "createdAt",
            align: "center",
            sorter: true,
            render: (record) => {
                return getMyDate(record);
            },
            filterDropdown: ({ }) => (
                <div
                    style={{
                        padding: 8,
                    }}
                >
                    <RangePicker
                        size="small"
                        onChange={(e, dateString) => setDate(dateString)}
                        renderExtraFooter={() => "extra footer"}
                    />
                </div>
            ),
            filterIcon: (filtered) => (
                <CalendarOutlined
                    style={{
                        color: JSON.parse(searchParams.get("between"))?.createdAt
                            ? "#1890ff"
                            : undefined,
                    }}
                />
            ),
        },
        {
            title: "",
            width: "50px",
            fixed: "right",
            align: "center",
            render: (text, record, index) => (
                <ActionButton
                    handleMenuClick={(e) =>
                        showPropsConfirm(
                            record.id,
                            deleteTable,
                            loading,
                            `${translation["DELETE"] || TEXT["DELETE"]}`,
                            "#bf4342",
                            `${translation["Are you sure you want to delete?"] ||
                            TEXT["Are you sure you want to delete?"]
                            }`,
                            `${translation["CANCEL"] || TEXT["CANCEL"]}`
                        )
                    }
                    text={""}
                    index={index}
                    icon={<DeleteOutlined />}
                />
            ),
        },
    ];

    const getBlacklist = () => {
        setLoading(true);
        const query = {
            page: currentPage,
            limit,
            pagination: 1,
            sort: JSON.stringify(sort),
            search: {},
        };

        if (search.mac) {
            query.search["mac"] = search.mac[0];
        }

        if (search.deviceId) {
            query.search["deviceId"] = search.deviceId[0];
        }

        if (search.ip) {
            query.search["ip"] = search.ip[0];
        }

        if (query.search) {
            query.search = JSON.stringify(query.search);
        }

        if (date && date[0]) {
            let to = new Date(date[1]);
            to.setDate(to.getDate() + 1);
            query.between = JSON.stringify({
                createdAt: {
                    from: new Date(date[0]),
                    to: new Date(to),
                },
            });
        }

        goToPosts(query);

        REQUESTS.BLACKLIST.GET(query, (data) => {
            setTotal(data.count);
            setLimit(data.limit);
            if (data.rows.length === 0 && currentPage > 1) {
                setCurrentPage((current) => current - 1);
            } else {
                setCurrentPage(data.currentPage);
            }
            setLoading(false);
            setBlacklist(data.rows);
        });
    };

    const handleTableChange = (pagination, filters, sorter) => {
        if (sorter.field) {
            setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
        }
        setSearch(filters);
        setCurrentPage(pagination.current);
        setLimit(pagination.pageSize);
    };

    const onClickAdd = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
        setEdiPackage(null);
    };

    useEffect(() => {
        let timout = setTimeout(() => {
            getBlacklist();
        }, 500);
        return () => {
            clearTimeout(timout);
        };
    }, [search, currentPage, limit, date, sort]);

    const followAddElement = {
        setCurrentPage,
        setSearch,
        setSort,
        setLimit,
        setTotal,
        setDate,
    };

    return (
        <div className={classes["blacklist_list_table"]}>
            <div className={classes["blacklist_list_title"]}>
                <p className={classes["blacklist_title-text"]}>
                    {/* {" "}
          <LockOutlined />  */}
                    {translation["Blacklist"] || TEXT["Blacklist"]}
                </p>
                <Button
                    className={classes["blacklist_add"]}
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={onClickAdd}
                />
            </div>

            <Table
                loading={loading}
                rowKey="id"
                columns={columns}
                dataSource={blacklist}
                onChange={handleTableChange}
                pagination={{
                    position: ["bottomCenter"],
                    current: currentPage,
                    total: total,
                    pageSize: limit,
                    showSizeChanger: true,
                }}
                scroll={{ x: "max-content" }}
                size="small"
                locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /> }}

            />

            <BlacklistDrawer
                visible={visible}
                onClose={closeDrawer}
                current={ediPackage}
                getData={getBlacklist}
                translation={translation}
                followAddElement={followAddElement}
            />
        </div>
    );
}

export default Blacklist;
