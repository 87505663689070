import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button, Form, Input } from "antd";
import { LockOutlined, ReloadOutlined } from "@ant-design/icons";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
import REQUESTS from "../../../api/requests";
import TEXT from "../../../config/text";
import ErrorMessage from "../../../components/ErrorMessage";
import classes from "../style/resetPassword.module.scss";

const ResetPasswordForm = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const translation = useSelector(selectTranslation);

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState({
        text: "",
        type: true,
    });


    const [form] = Form.useForm();

    const onFinish = (values) => {
        setLoading(true);

        const body = {
            code: searchParams.get("code"),
            password: values.password,
            confirm_password: values.confirm,
        };

        function callback(data) {
            setLoading(false);
            setMessage({
                text: data,
                type: true,
            });

            setTimeout(() => {
                navigate("/login");
            }, 2000);
        }

        function errorCallback(error) {
            setLoading(false);
            setMessage({
                text: error,
                type: false,
            });
        }

        REQUESTS.RESET_PASSWORD(body, callback, errorCallback);
    };

    const onFinishFailed = (error) => {
        setLoading(false);
        if(typeof error === "string") {
            setMessage({
                text: error,
                type: false,
            });
        };
    };

    const onValueChange = () => {
        removeMessage();
    };

    const removeMessage = () => {
        setMessage({
            text: '',
            type: true,
        });
    };

    const onSubmit = () => {
        removeMessage();
        form.submit();
    };

    return (
        <Form
            form={form}
            name="reset-form"
            className="login-form"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onValuesChange={onValueChange}
        >
            <div className={classes['form-input-block']}>
                <Form.Item
                    name="password"
                    rules={[
                        {
                            min: 8,
                            required: true,
                            message: translation["Please input your password!"] || TEXT["Please input your password!"],
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password
                        size="large"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder={translation["New password"] || TEXT["New password"]}
                    />
                </Form.Item>

                <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                        {
                            min: 8,
                            required: true,
                            message: translation["Please confirm your password!"] || TEXT["Please confirm your password!"],
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }

                                return Promise.reject(
                                    new Error("The two passwords that you entered do not match!")
                                );
                            },
                        }),
                    ]}
                >
                    <Input.Password
                        size="large"
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        placeholder={translation["Confirm password"] || TEXT["Confirm password"]}
                    />
                </Form.Item>
            </div>

            <ErrorMessage message={message} />

            <Form.Item className={classes["form-items-2"]}>
                <Button
                    loading={loading}
                    size={"large"}
                    type="primary"
                    className="login-form-button"
                    icon={<ReloadOutlined />}
                    onClick={onSubmit}
                >
                    {translation["Reset password"] || TEXT["Reset password"]}
                </Button>
            </Form.Item>
        </Form>
    );
};

export default ResetPasswordForm;

