import { useState, useEffect } from "react";

import TEXT from "../../../config/text";
import REQUESTS from "../../../api/requests";
import classes from "../style/smtpconfig.module.scss";
import ErrorMessage from "../../../components/ErrorMessage";
import { Button, Form, Input, notification } from "antd";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../../features/Translation/TranslationSlice";
const { TextArea } = Input;

const SMTPConfig = () => {
  const [loading, setLoading] = useState(false);
  const [smptConfig, setSmptConfig] = useState([]);
  const [message, setMessage] = useState({
    type: false,
    text: "",
  });
  const translation = useSelector(selectTranslation);

  const [form] = Form.useForm();

  const openNotification = (message) => {
    const args = {
      message: `${translation["SMTP"] || TEXT["SMTP"]}`,
      description: `${message}`,
      duration: 5,
    };
    notification.open(args);
  };

  const onValuesChange = (e) => {
    setMessage({
      type: false,
      text: "",
    });
  };

  const getSmptConfig = () => {
    const collback = (data) => {
      setSmptConfig(data);
    };

    REQUESTS.SMPT.GET(collback);
  };



  useEffect(() => {
    getSmptConfig();
  }, []);

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      host: values.host,
      port: values.port,
      email: values.email,
      reaciver_email: values.reaciver_email,
      password: values.password,
      id: smptConfig[0]?.id,
    };

    function callback(data) {
      setLoading(false);
      getSmptConfig();
      //    setMessage({ type: true, text: data });
      openNotification(data);
    }

    function errorCallback(err) {
      setLoading(false);
      setMessage({ type: false, text: err });
    }

    REQUESTS.SMPT.EDIT(body, callback, errorCallback);
  };

  useEffect(() => {
    if (smptConfig) {
      form.setFields([
        {
          name: "host",
          value: smptConfig[0]?.host,
        },

        {
          name: "port",
          value: smptConfig[0]?.port,
        },

        {
          name: "email",
          value: smptConfig[0]?.email,
        },
        {
          name: "reaciver_email",
          value: smptConfig[0]?.reaciver_email,
        },
        {
          name: "password",
          value: smptConfig[0]?.password,
        },
      ]);
    } else {
      form.resetFields();
    }
  }, [smptConfig]);

  return (
    <Form
      className={classes["smpt_form"]}
      form={form}
      name="profile"
      layout="vertical"
      onFinish={onFinish}
      onValuesChange={onValuesChange}

    >
      <Form.Item
        label={translation["Host"] || TEXT["Host"]}
        name="host"
        rules={[
          {
            required: true,
            message: "Please input host",
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={translation["Port"] || TEXT["Port"]}
        name="port"
        rules={[
          {
            required: true,
            message: "Please input port",
          },
        ]}
      >
        <Input type="number" min={0} />
      </Form.Item>

      <Form.Item
        label="Username"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input username",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Reaciver email"
        name="reaciver_email"
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={translation["Password"] || TEXT["Password"]}
        name="password"
        rules={[
          {
            required: true,
            message: "Please input password",
          },
        ]}
      >
        <Input.Password />
      </Form.Item>

      <ErrorMessage message={message} />
      <div className={classes["save_button_div"]}>
        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            className={classes["save_button"]}
            loading={loading}
          >
            {translation["Save"] || TEXT["Save"]}
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
};

export default SMTPConfig;
