import React, { memo, useEffect, useState } from "react";

import { useSelector } from "react-redux";

import { Table, DatePicker, Empty } from "antd";
import { CalendarOutlined } from "@ant-design/icons";

import REQUESTS from "../../../api/requests";

import getMyDate from "../../../components/getMyDate";

import { getColumnSearchProps } from "../../../utils/index";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";

import ResellerInfoDrawer from "./ResellerInfoDrawer";

import "../reseller.scss"

const { RangePicker } = DatePicker;

function ResellerActivationHistory({ current }) {

  const [data, setData] = useState([]);
  const [total, setTotal] = useState(10);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [date, setDate] = useState("");
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState(null);

  const translation = useSelector(selectTranslation);

  const [showInfo, setShowInfo] = useState(null)

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      align: "center",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: `${translation["Method"] || TEXT["Method"]}`,
      dataIndex: "method",
      align: "center",
      ...getColumnSearchProps(),
      render: (text, record, index) => {
        let paymentInfo;
        try {
          paymentInfo = JSON.parse(record.payment_info);
        } catch (error) { }
        const method = text === "to sub reseller" || text === "back to balance" || text === "activation added" || text === "activation taken"
        return <span className={method && (paymentInfo?.reseller_id || paymentInfo?.sub_reseller_id) ? "method-link" : ""}>{text}</span>
      }
    },
    {
      title: `${translation["Transaction id"] || TEXT["Transaction id"]}`,
      dataIndex: "transaction_id",
      align: "center",
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Count"] || TEXT["Count"]}`,
      dataIndex: "count",
      align: "center",
      sorter: true,
    },
    {
      title: `${translation["Price"] || TEXT["Price"]}`,
      dataIndex: "price",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["Created date"] || TEXT["Created date"]}`,
      sorter: true,
      dataIndex: "createdAt",
      align: "center",
      render: (text, record, index) => text && getMyDate(text),
      filterDropdown: ({ }) => (
        <div
          style={{
            padding: 8,
          }}
        >
          <RangePicker
            size="small"
            onChange={(e, dateString) => setDate(dateString)}
            renderExtraFooter={() => "extra footer"}
          />
        </div>
      ),
      filterIcon: (filtered) => <CalendarOutlined />,
    },
  ];

  const getResellerData = (obj, type) => {
    if (!obj[type]) return;

    const query = {
      filter: JSON.stringify({
        id: obj[type]
      })
    }

    const callback = (response) => setShowInfo(response.rows?.[0] || {});

    if (type === 'sub_reseller_id') {
      REQUESTS.RESELLER_SUBRESELLERS(query, callback)
    } else {
      REQUESTS.GET_RESELLERS(query, callback)
    }
  }

  const onTableRowClick = (record) => {
    console.log(record)
    try {
      const resellerId = JSON.parse(record.payment_info) || {};

      let condition = record?.method === "to sub reseller" || record?.method === "back to balance" || record?.method === "activation added" || record?.method === "activation taken";

      let type = record?.method === "to sub reseller" || record?.method === "back to balance" ? "sub_reseller_id" : record?.method === "activation added" || record?.method === "activation taken" ? "reseller_id" : null;

      if (condition) getResellerData(resellerId, type);
    } catch (error) {
      console.error(error);
    }
  }

  const getActivationHistory = () => {
    setLoading(true);

    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      filter: JSON.stringify({ reseller_id: current?.id }),
    };

    if (search) {
      query.search = {};

      if (search.method) {
        query.search["method"] = search.method[0];
      }

      if (search.transaction_id) {
        query.search["transaction_id"] = search.transaction_id[0];
      }

      query.search = JSON.stringify(query.search);
    }

    if (date && date[0]) {
      let to = new Date(date[1]);
      to.setDate(to.getDate() + 1);
      query.between = JSON.stringify({
        createdAt: {
          from: new Date(date[0]),
          to: new Date(to),
        },
      });
    }

    REQUESTS.RESELLER_ACTIVATIONS_HISTORY(
      query,
      (data) => {
        setLoading(false);
        setTotal(data.count);
        setLoading(false);
        setData(data.rows);
      },
      () => {
        setLoading(false);
      }
    );
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setSearch(filters);

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {

    if (!current) {
      setDate('');

      return;
    }

    let timout = setTimeout(getActivationHistory, 500);

    return () => clearTimeout(timout);

  }, [search, currentPage, limit, sort, date, current]);

  return (
    <div>
      <p className="reseller-info-drawer-title">Credits history</p>
      <Table
        loading={loading}
        columns={columns}
        rowKey="id"
        dataSource={data}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" /> }}

        onRow={(record, rowIndex) => {
          return {
            onClick: () => onTableRowClick(record),
          };
        }}
      />

      <ResellerInfoDrawer
        visible={showInfo}
        onClose={() => setShowInfo(null)}
        current={showInfo}
      />
    </div>
  );
}


export default memo(ResellerActivationHistory);