const CONSTANTS = {
    JWT: "JWT",
    REMEMBER_ME: "REMEMBER_ME",

    APP_NAME: window.APP_NAME || "XPLAYER ADMIN",
    API_HOST: window.API_URL || "http://api.x-players.com",

    APP_COLOR: "black",
    LINK_COLOR: "white",
};

export default CONSTANTS;
