import StatusList from "../packages/components/StatusList";

function Statuses() {
  return (
    <div>
      <StatusList />
    </div>
  );
}

export default Statuses;
