import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";

import { selectTranslation } from "../../../features/Translation/TranslationSlice";

import TEXT from "../../../config/text";

import ICONS from "../../../config/icons";

import REQUESTS from "../../../api/requests";

import classes from "../index.module.scss";

export default function ServerStatistics() {
  const [server, setServer] = useState({});
  const translation = useSelector(selectTranslation);

  const getServerStatistics = () => {
    REQUESTS.STATISTICS.SERVER_STATE(setServer);
  };

  useEffect(() => {
    getServerStatistics();
  }, []);

  return (
    <div className={classes["server-statistics"]}>
      <div className={classes["title-section"]}>
        <h2 className={classes["title"]}>
          Servers
        </h2>
        {ICONS.SERVER}
      </div>
      <div className={classes['items']}>
        <div className={classes["item-container"]}>
          <div
            style={{ background: "#ED5B75" }}
            className={classes["single-item"]} />

          <span className={classes["server-txt"]}>
            {translation["Ram"] || TEXT["Ram"].toUpperCase()} :{" "}
            <span className={classes["server-txt_item"]}>{server?.ram || ""}</span>
          </span>
        </div>
        <div className={classes["item-container"]}>
          <div
            style={{ background: "#FBB142" }}
            className={classes["single-item"]}
          />
          <span className={classes["server-txt"]}>
            {translation["Cpu"] || TEXT["Cpu"].toUpperCase()}
            {" "}
            : <span className={classes["server-txt_item"]}>{server?.cpu || ""}</span>
          </span>
        </div>
        <div className={classes["item-container"]}>
          <div
            style={{ background: "#4FDFB1" }}
            className={classes["single-item"]}
          />
          <span className={classes["server-txt"]}>
            {translation["Uptime"] || TEXT["Uptime"].toUpperCase()} :{" "}
            <span className={classes["server-txt_item"]}>{server?.uptime || ""}</span>
          </span>
        </div>
        <div className={classes["item-container"]}>
          <div
            style={{ background: "#FBB142" }}
            className={classes["single-item"]}
          />
          <span className={classes["server-txt"]}>
            {translation["Total"] || TEXT["Total"].toUpperCase()} :{" "}
            <span className={classes["server-txt_item"]}>{server?.disk?.total || ""}</span>
          </span>
        </div>
        <div className={classes["item-container"]}>
          <div
            style={{ background: "#ED5B75" }}
            className={classes["single-item"]}
          />
          <span className={classes["server-txt"]}>
            {translation["Total Used"] || TEXT["Total Used"].toUpperCase()} :{" "}
            <span className={classes["server-txt_item"]}>{server?.disk?.total_used || ""}</span>
          </span>
        </div>
      </div>
    </div>
  );
}
