import { useEffect, useState } from 'react';

import { Button, Drawer, Form, notification, Modal, Alert } from 'antd';

import REQUESTS from '../../../api/requests';

import ICONS from '../../../config/icons';

import ErrorMessage from '../../../components/ErrorMessage';

import MacMaskComponent from '../../../components/MacMaskComponent';

import style from "../index.module.scss";

export default function DeviceTransfer({ open, onClose, current }) {
    const [form] = Form.useForm();

    const [macIsValid, setMacIsValid] = useState(false);

    const [mac, setMac] = useState(null);

    const [device, setDevice] = useState(null);

    const [message, setMessage] = useState({
        type: false,
        text: ''
    });

    const handleTransfer = () => {
        Modal.confirm({
            title: 'Transfer Device',
            content: `You are going to transfer your activation from this ${current.mac} MAC to this ${mac} MAC.
                     The payment and playlist info will be transferred as well.`,
            onOk: onFinish,
            okText: 'Yes',
            cancelText: 'No',
        });
    }

    const onFinish = () => {
        setMessage({
            type: false,
            text: ""
        })

        if (!current?.id && !device?.id) return;

        if (current?.id == device?.id) {
            setMessage({
                type: false,
                text: 'You can not transfer device to the same device'
            })
            return;
        }

        const body = {
            from_id: current.id,
            to_id: device.id,
        }

        function callback(response) {
            onClose();

            notification.success({
                message: 'Success',
                description: 'Device transferred successfully'
            })
        };

        function errorCallback(error) {
            setMessage({
                type: false,
                text: error
            })
        };

        REQUESTS.TRANSFER_DEVICE(body, callback, errorCallback)
    }

    useEffect(() => {
        if (!open) {
            form.resetFields();
            setMacIsValid(false);
            setMac(null);
            setDevice(null);
            setMessage({
                type: false,
                text: ''
            })
        }
    }, [open]);

    return (
        <Drawer
            title="Device Transfer"
            open={open}
            onClose={onClose}
            destroyOnClose={true}
        >
            <Alert
                message="The payment and playlist info will be transferred as well."
                type="info"
                icon={<span className={style['transfer-icon']}>{ICONS.TRANSFER_DEVICE}</span>}
                showIcon
                style={{ marginBottom: '20px' }}
            />

            <Form
                form={form}
                layout='vertical'
                onFinish={onFinish}
            >
                <MacMaskComponent
                    value={mac}
                    setValue={setMac}
                    setMacIsValid={setMacIsValid}
                    setDevice={setDevice}
                    placeholder='Enter MAC Address'
                />
                <ErrorMessage message={message} />
                <Form.Item >
                    <Button disabled={!macIsValid} type='primary' onClick={handleTransfer} style={{ width: "100%" }}>Transfer</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}
