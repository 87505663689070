import REQUESTS from "../../api/requests";
import { useEffect, useState, useContext } from "react";
import { Table, Button, Input, Modal } from "antd";
import classes from "./index.module.scss";
import { useSelector } from "react-redux";
import { selectTranslation } from "../../features/Translation/TranslationSlice";
import {
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import TEXT from "../../config/text";
import { SearchOutlined } from "@ant-design/icons";
import InitialParams from "../../components/InitialParams";
import { emptyContext } from "../../context_empty/context_empty";
const { confirm } = Modal;

function Withdraw() {
  const [empty, setEmpty] = useContext(emptyContext);
  const [searchParams] = useSearchParams();
  const [total, setTotal] = useState();
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [withdraw, setWithdraw] = useState([]);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [search, setSearch] = useState({
    name: "",
    ip: "",
    swift: "",
    country: "",
  });

  InitialParams(setLimit, setCurrentPage, setSearch, setSort);

  const navigate = useNavigate();
  const goToPosts = (params) =>
    navigate({
      search: `?${createSearchParams(params)}`,
    });

  const translation = useSelector(selectTranslation);
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => {
      return (
        <Input
          allowClear
          autoFocus
          placeholder="Type text here"
          value={selectedKeys[0]}
          onChange={(e) => {
            setSelectedKeys(e.target.value ? [e.target.value] : []);
            confirm({ closeDropdown: false });
          }}
        />
      );
    },
    filterIcon: () => {
      return (
        <>
          <SearchOutlined />
        </>
      );
    },
  });

  const updateTable = (id) => {
    const callback = (data) => {
      getWithdraw();
    };

    const errorCallback = (data) => {};

    REQUESTS.WITHDRAW.UPDATE(id, callback, errorCallback);
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      width: "50px",
      align: "center",
      render: (text, record, index) => {
        if (currentPage === 1) {
          return index + 1;
        } else {
          return limit * (currentPage - 1) + index + 1;
        }
      },
    },

    {
      title: `${translation["Referral"] || TEXT["Referral"]}`,
      dataIndex: "reseller",
      key: "name",
      align: "center",
      render: (record, text, index) => {
        if (record?.name && record?.surname) {
          return <p>{`${record?.name} ${record?.surname}`}</p>;
        } else {
          return <p>N/A</p>;
        }
      },
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Ip"] || TEXT["Ip"]}`,
      dataIndex: "reseller",
      key: "ip",
      align: "center",
      render: (record, text, index) => {
        return record?.ip;
      },
      ...getColumnSearchProps(),
    },
    {
      title: `${translation["Country"] || TEXT["Country"]}`,
      dataIndex: "country",
      key: "country",
      align: "center",
      ...getColumnSearchProps(""),
      render: (record, text, index) => {
        return record ? record : "N/A";
      },
    },

    {
      title: `${translation["Method"] || TEXT["Method"]}`,
      dataIndex: "method",
      sorter: true,
      align: "center",
    },
    {
      title: `${translation["BIC/SWIFT code"] || TEXT["BIC/SWIFT code"]}`,
      dataIndex: "swift",
      key: "swift",
      align: "center",
      ...getColumnSearchProps(""),
      render: (record, text, index) => {
        return record ? record : "N/A";
      },
    },
    {
      title: `${translation["Amount"] || TEXT["Amount"]}`,
      dataIndex: "amount",
      align: "center",
      sorter: true,
    },
  ];

  const getWithdraw = () => {
    setLoading(true);
    const query = {
      page: currentPage,
      limit,
      pagination: 1,
      sort: JSON.stringify(sort),
      search: {},
    };

    if (search.country) {
      query.search["country"] = search.country[0];
    }

    if (search.name) {
      query.search["$reseller.name$"] = search.name[0];
    }

    if (search.ip) {
      query.search["$reseller.ip$"] = search.ip[0];
    }

    if (search.swift) {
      query.search["swift"] = search.swift[0];
    }

    if (query.search) {
      query.search = JSON.stringify(query.search);
    }

    goToPosts(query);

    REQUESTS.WITHDRAW.GET(query, (data) => {
      setTotal(data.count);
      // setLimit(data.limit);
      if (data.rows.length === 0 && currentPage !== 1) {
        setCurrentPage((current) => current - 1);
      } else {
        setCurrentPage(data.currentPage);
      }
      setLoading(false);
      setWithdraw(data.rows);
      if (!data.rows == []) {
        setEmpty(false);
      }
    });
  };

  useEffect(() => {
    return () => {
      setEmpty(true);
    };
  }, []);

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    if (
      search.name !== filters.name ||
      search.ip !== filters.ip ||
      search.swift !== filters.swift ||
      search.country !== filters.country
    ) {
      setSearch({
        name: filters.name,
        ip: filters.ip,
        swift: filters.swift,
        country: filters.country,
      });
    }

    if (Object.values(filters)[0]) {
      //   setTitleSearch(Object.values(filters)[0].join(""));
    } else {
      //   setTitleSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  useEffect(() => {
    let timout = setTimeout(() => {
      getWithdraw();
    }, 500);
    return () => {
      clearTimeout(timout);
    };
  }, [search, currentPage, limit, sort]);

  return (
    <div className={classes["withdraw_list_table"]}>
      <div className={classes["withdraw_list_title"]}>
        <p className={classes["withdraw_title-text"]}>
          {/* <CreditCardOutlined /> */}

          {translation["Withdraw"] || TEXT["Withdraw"]}
        </p>
      </div>

      <Table
        loading={loading}
        rowKey="id"
        columns={columns}
        dataSource={withdraw}
        onChange={handleTableChange}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{ x: "max-content" }}
        size="small"
      />
    </div>
  );
}

export default Withdraw;
